import { Dialog, DialogActions, DialogTitle, Button } from '@mui/material';
// import { useWorkersService } from '@restworld/data-services';
import LoadingButton from '@mui/lab/LoadingButton';
// import useComponentStatus from 'hooks/useComponentStatus';

type Props = {
  isOpen: boolean;
  actionText: string;
  onClose: () => void;
  action: any;
  isLoading?: boolean;
};

export default function ActionConfirmationModal({
  isOpen,
  actionText,
  onClose,
  isLoading,
  action
}: Props) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center', my: 3 }}>{actionText}</DialogTitle>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Indietro
        </Button>
        <LoadingButton variant="contained" color="primary" onClick={action} loading={isLoading}>
          Conferma
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
