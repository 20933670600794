import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container, IconButton, Stack } from '@mui/material';
// hooks
import useOffSetTop from 'hooks/useOffSetTop';
import useAlgoliaSearch from 'hooks/useAlgoliaSearch';
// components
import Logo from '../../components/Logo';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

import AccountPopover from '../dashboard/AccountPopover';
import { useContext, useMemo } from 'react';
import { SidebarContext } from 'contexts/SidebarContext';
import { SIDEBAR_TRANSITION_DURATION } from 'config';
import { useTheme } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import TaskManagerLite from 'components/TaskManagerLite';
import useAuth from 'hooks/useAuth';
import GlobalSlotUsageStatus from './GlobalSlotUsageStatus';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  to?: string;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

export type MenuProps = {
  isOffset: boolean;
  isHome: boolean;
  navConfig: MenuItemProps[];
  isCollapsed?: boolean;
};

export default function MainNavbar() {
  const isOffset = useOffSetTop(30);
  const theme = useTheme();
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const { contentWidth, sidebarStatus } = useContext(SidebarContext);
  const { handleToggle } = useAlgoliaSearch();

  const { user: loggedInUser } = useAuth();

  const loggedInUserRoles = useMemo(
    () => loggedInUser?.data?.roles?.map((role: any) => role?.key),
    [loggedInUser]
  );

  const navConfigFiltered = useMemo(() => {
    if (loggedInUserRoles?.includes('outsourcing_sales')) {
      return navConfig?.filter(
        (item) =>
          item?.path !== '/job-positions' &&
          item?.path !== '/restaurants' &&
          item?.path !== '/employers' &&
          item?.path !== '/organizations'
      );
    } else {
      return navConfig?.filter((item) => item?.path !== '/history');
    }
  }, [loggedInUserRoles]);

  return (
    <AppBar
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        width: contentWidth,
        left: sidebarStatus.left.width,
        right: sidebarStatus.right.width,
        transition: theme.transitions.create(['width', 'left'], {
          duration: SIDEBAR_TRANSITION_DURATION
        }),
        zIndex: 500
      }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth={'xl'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo color="violet" />
          </RouterLink>
          {/* <Label color="info" sx={{ ml: 1 }}>
            Simple Ts v2.6.0
          </Label> */}

          <Box sx={{ flexGrow: 0.1 }} />
          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfigFiltered} />
          </MHidden>
          <Box sx={{ flexGrow: 1 }} />

          {/* <Button
            variant="contained"
            target="_blank"
            href="https://material-ui.com/store/items/minimal-dashboard/"
          >
            Purchase Now
          </Button> */}
          <IconButton onClick={handleToggle}>
            <SearchIcon />
          </IconButton>
          <Stack marginX={2} spacing={0} direction="row">
            <TaskManagerLite />
            <GlobalSlotUsageStatus />
          </Stack>
          <AccountPopover />
          {/* {!isCollapse && (
            <IconButton onClick={handleToggle}>
              <FilterList />
            </IconButton>
          )} */}

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfigFiltered} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
