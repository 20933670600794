import { useCallback, useState } from 'react';
import { apiCacheStore } from 'utils/apiCache';

export default function useApiCache() {
  const [cacheUrls, updateCacheUrls] = useState<any>({});

  const updateApiCache = useCallback((apiCacheUrl: string, updateCb: (data: any) => any) => {
    if (apiCacheUrl) {
      const prevResponse = apiCacheStore.get(apiCacheUrl);
      if (prevResponse) {
        const updatedResponse = updateCb(prevResponse);
        apiCacheStore.add(apiCacheUrl, updatedResponse);
      }
    }
  }, []);

  return {
    apiCacheUrls: cacheUrls,
    updateCacheUrls,
    updateApiCache
  };
}
