import {
  CreditCardOff as CreditCardOffIcon,
  CreditCard as CreditCardIcon,
  WarningAmber as WarningAmberIcon,
  WorkspacePremium as WorkspacePremiumIcon
} from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Dialog
} from '@mui/material';
import { StripeSubscription } from '@restworld/utility-types';
import { differenceInDays, add } from 'date-fns';
import SubscriptionTable from './SubscriptionTable';
import { useCallback, useEffect, useState } from 'react';
import FlexibleSlotsTable from 'pages/showOrganization/flexible-slots-table-dialog/flexible-slots-table';
import { useAccountService } from 'service';

interface Props {
  hasPaymentMethod: boolean | null;
  subscriptionMissingDays: number[] | null;
  activeSubscriptions: StripeSubscription[] | null;
  type?: 'jobPosition' | 'employer';
  isSuccessFee?: boolean;
  jobPositionInsertedAt?: Date;
  organizationId: string;
}
export default function SubscriptionStatus({
  hasPaymentMethod,
  activeSubscriptions,
  subscriptionMissingDays,
  type = 'employer',
  isSuccessFee = false,
  jobPositionInsertedAt,
  organizationId
}: Props) {
  const tempAccountsService = useAccountService();
  const successFeeMissingDays = !!jobPositionInsertedAt
    ? differenceInDays(add(jobPositionInsertedAt, { days: 30 }), new Date())
    : 0;

  const [subscriptionDetailsModalOpen, setSubscriptionDetailsModalOpen] = useState(false);

  const [hasFlexibleSlots, setHasFlexibleSlots] = useState<boolean>(false);

  const checkActiveSlotExistence = useCallback(() => {
    organizationId &&
      tempAccountsService
        .listFlexibleSlots({ organizationId, limit: 1, offset: 0 })
        .then((res) => {
          const hasActiveSlot = res.data.data.some((slot) => slot.status !== 'expired');
          if (hasActiveSlot) {
            setHasFlexibleSlots(true);
          } else {
            setHasFlexibleSlots(false);
          }
        })
        .catch((error) => {
          console.error({ error });
        });
  }, [organizationId, tempAccountsService]);

  useEffect(() => checkActiveSlotExistence(), [checkActiveSlotExistence]);

  return (
    <>
      <Dialog
        open={subscriptionDetailsModalOpen}
        onClose={() => setSubscriptionDetailsModalOpen(false)}
        PaperProps={{ sx: { minWidth: '50%' } }}
      >
        <DialogContent>
          <SubscriptionTable activeSubscriptions={activeSubscriptions} />
          <FlexibleSlotsTable organizationId={organizationId} />
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            variant="contained"
            onClick={() => setSubscriptionDetailsModalOpen(false)}
          >
            Fine
          </Button>
        </DialogActions>
      </Dialog>

      <Stack direction="row" spacing={1} alignItems="baseline">
        {hasPaymentMethod === false ? (
          <Tooltip title="Nessun metodo di pagamento registrato">
            <CreditCardOffIcon color="error" fontSize="small" />
          </Tooltip>
        ) : (
          <Tooltip title="Metodo di pagamento registrato">
            <CreditCardIcon color="success" fontSize="small" />
          </Tooltip>
        )}
        {(subscriptionMissingDays !== null &&
          !!activeSubscriptions &&
          activeSubscriptions.length > 0) ||
        hasFlexibleSlots ? (
          <>
            <Tooltip title="Abbonamento / Flex Slot attivi, clicca per avere più informazioni">
              <IconButton
                color={
                  subscriptionMissingDays
                    ? subscriptionMissingDays.some((missingDays) => missingDays < 0)
                      ? 'error'
                      : subscriptionMissingDays.some((missingDays) => missingDays <= 3)
                      ? 'warning'
                      : 'success'
                    : 'success'
                }
                onClick={() => setSubscriptionDetailsModalOpen(true)}
              >
                <WorkspacePremiumIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : hasPaymentMethod === true ? (
          <Tooltip title="Nessun abbonamento attivo">
            <WarningAmberIcon color="error" fontSize="small" />
          </Tooltip>
        ) : null}
        {type === 'jobPosition' && isSuccessFee && successFeeMissingDays >= 0 && (
          <Typography variant="caption">
            Ricerca in success fee, <b>{successFeeMissingDays}</b> giorni mancanti
          </Typography>
        )}
        {type === 'jobPosition' && isSuccessFee && successFeeMissingDays < 0 && (
          <Typography variant="caption">
            Ricerca in success fee,{' '}
            <b>scaduta da {!!successFeeMissingDays && Math.abs(successFeeMissingDays)}</b> giorni
          </Typography>
        )}
      </Stack>
    </>
  );
}
