import { DelayFunctionContext } from 'contexts/DelayFunctionContext';
import { useContext } from 'react';

const useDelayFunction = () => {
  const context = useContext(DelayFunctionContext);

  if (!context) throw new Error('DelayFunctionContext must be inside DelayFunctionProvider');

  return context;
};

export default useDelayFunction;
