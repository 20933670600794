import { DeleteOutline, Edit } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useCallback, useMemo, useState } from 'react';
import { FlexibleSlot } from 'service/accounts';
import EditFlexibleSlot from './edit-flexible-slot';
import { useAccountService } from 'service';
import Loader from 'components/Loader';

interface Props {
  isOpen: boolean;
  anchorEl: null | HTMLElement;
  handleMenuClose: () => void;
  flexibleSlot?: FlexibleSlot;
  refreshSlots: () => void;
}
export default function FlexibleSlotActions({
  handleMenuClose,
  anchorEl,
  isOpen,
  flexibleSlot,
  refreshSlots
}: Props) {
  const { user } = useAuth();
  const accountsService = useAccountService();
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);

  const isAdminLoggedIn = useMemo(() => {
    return (
      !!user &&
      !!user?.data &&
      (user?.data?.email === 'lorenzo@restworld.it' ||
        user?.data?.email === 'arianna@restworld.it' ||
        user?.data?.email === 'viviana@restworld.it')
    );
  }, [user]);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);

  const hasOneDayPassed = useMemo(() => {
    if (!flexibleSlot?.activated_at) return false;
    const today = new Date();
    const activatedAt = new Date(flexibleSlot?.activated_at);
    return today.getDate() - activatedAt.getDate() >= 1;
  }, [flexibleSlot]);

  const handleEditDialogOpen = () => {
    setIsEditDialogOpen(true);
  };
  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
  };

  const handleDeactivateFlexibleSlot = useCallback(() => {
    if (!flexibleSlot) return;
    setIsDeactivating(true);
    accountsService
      .updateFlexibleSlot({ id: flexibleSlot?.id, data: { status: 'idle' } })
      .then(() => {
        refreshSlots();
        setIsDeactivating(false);
      })
      .catch((err) => {
        console.error(err);
        setIsDeactivating(false);
      });
  }, [accountsService, flexibleSlot, refreshSlots]);

  return (
    <>
      {!!flexibleSlot && (
        <EditFlexibleSlot
          isOpen={isEditDialogOpen}
          flexibleSlot={flexibleSlot}
          onClose={handleEditDialogClose}
          refreshSlots={refreshSlots}
        />
      )}
      <Menu
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        open={isOpen}
        //   sx={{ zIndex: zIndex || 1299 }}
      >
        <MenuItem onClick={handleEditDialogOpen} disabled={!isAdminLoggedIn}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Modifica</Typography>
          </ListItemText>
        </MenuItem>
        {flexibleSlot?.status === 'active' && (
          <MenuItem
            onClick={handleDeactivateFlexibleSlot}
            disabled={hasOneDayPassed && !isAdminLoggedIn}
          >
            <ListItemIcon>
              {isDeactivating ? <Loader position="relative" /> : <DeleteOutline color="error" />}
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">Annulla</Typography>
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
