import { Tooltip } from '@mui/material';
import { JobApplicationSource as JobApplicationSourceType } from '@restworld/utility-types';
import Logo from 'components/Logo';
import Iconify from 'components/Iconify';
import JobRapidoIcon from 'assets/jobrapido_icon';
import BakecaIcon from 'assets/bakeca_icon';
import JoobleIcon from 'assets/jooble_icon';
import { Google, Telegram } from '@mui/icons-material';

const iconStyle = {
  width: 30,
  height: 30,
  color: 'grey.600'
};

export default function JobApplicationSource({
  source: baseSource,
  ambassadorCode
}: {
  source?: JobApplicationSourceType | 'Adzuna' | 'adzuna' | 'Jooble';
  ambassadorCode?: string;
}) {
  const source = baseSource?.split('_adv')[0];

  let source_icon;
  if (source === null) {
    source_icon = (
      <Tooltip title="Il candidato si è candidato tramite il sito, la piattaforma oppure ha ricevuta un proposta da un CSM">
        <Logo sx={{ width: 30, height: 30 }} />
      </Tooltip>
    );
  } else if (source === 'passive' || source === 'automatic_proposals') {
    source_icon = (
      <Tooltip title="La posizione è stata proposta dal sistema">
        <Logo sx={{ width: 30, height: 30 }} />
      </Tooltip>
    );
  } else if (source === 'active_public_offers') {
    source_icon = (
      <Tooltip title="Il worker si è candidato dal sito web di Restworld">
        <Logo sx={{ width: 30, height: 30 }} />
      </Tooltip>
    );
  } else if (source === 'save_the_research') {
    source_icon = (
      <Tooltip title="Il worker si è candidato tramite una ricerca salvata sul sito">
        <Logo sx={{ width: 30, height: 30 }} />
      </Tooltip>
    );
  } else if (source === 'active_worker_offers') {
    source_icon = (
      <Tooltip title="Il worker si è candidato dalle offerte nella sua area privata">
        <Logo sx={{ width: 30, height: 30 }} />
      </Tooltip>
    );
  } else if (source === 'ActiveCampaign') {
    source_icon = (
      <Tooltip title="Campagna di email marketing: Active campign">
        <span>
          <Iconify icon="logos:active-campaign-icon" sx={iconStyle} />
        </span>
      </Tooltip>
    );
  } else if (source === 'Bakeca') {
    source_icon = (
      <Tooltip title="Bakeca.it">
        <BakecaIcon />
      </Tooltip>
    );
  } else if (source === 'google_jobs_apply') {
    source_icon = (
      <Tooltip title="Google Jobs">
        <Google />
      </Tooltip>
    );
  } else if (source === 'Indeed') {
    source_icon = (
      <Tooltip title="Indeed">
        <span>
          <Iconify icon="simple-icons:indeed" sx={iconStyle} />
        </span>
      </Tooltip>
    );
  } else if (source === 'JobRapido') {
    source_icon = (
      <Tooltip title="JobRapido">
        <JobRapidoIcon />
      </Tooltip>
    );
  } else if (source === 'Jobsora') {
    source_icon = (
      <Tooltip title="Jobsora">
        <span>
          <Iconify icon="bx:question-mark" sx={iconStyle} />
        </span>
      </Tooltip>
    );
  } else if (source === 'jooble' || source === 'Jooble') {
    source_icon = (
      <Tooltip title="Jooble">
        <JoobleIcon />
      </Tooltip>
    );
  } else if (source === 'Talent') {
    source_icon = (
      <Tooltip title="Talent">
        <img
          src="https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/common_icons/talent.png"
          alt="Talent"
          style={{ opacity: 0.6, width: 30, height: 30 }}
        />
      </Tooltip>
    );
  } else if (source === 'Adzuna' || source === 'adzuna') {
    source_icon = (
      <Tooltip title="Adzuna">
        <img
          src="https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/common_icons%2Flogo%20adzuna.png"
          alt="Adzuna"
          style={{ opacity: 0.6, width: 30, height: 30 }}
        />
      </Tooltip>
    );
  } else if (source === 'Telegram') {
    source_icon = (
      <Tooltip title="Telegram">
        <Telegram sx={iconStyle} />
      </Tooltip>
    );
  } else if (source === 'InfoJobs') {
    source_icon = (
      <Tooltip title="InfoJobs">
        <img
          src="https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/common_icons/infojob_logo.png"
          alt="InfoJobs logo"
          style={{ width: 30, height: 30 }}
        />
      </Tooltip>
    );
  } else if (source === 'cuochisottobotta') {
    source_icon = (
      <Tooltip title="Cuochi sotto botta">
        <img
          src="https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/common_icons/cuochisottobotta_logo.png"
          alt="cuochi sotto botta logo"
          style={{ width: 30, height: 30 }}
        />
      </Tooltip>
    );
  } else if (source === 'Monster') {
    source_icon = (
      <Tooltip title="Monster">
        <img
          src="https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/common_icons/monster_logo.png"
          alt="Monster logo"
          style={{ width: 30, height: 30 }}
        />
      </Tooltip>
    );
  } else if (source === 'referral') {
    source_icon = (
      <Tooltip title={`Referral da ${ambassadorCode}`}>
        <img
          src="https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/common_icons/challenge-logo.png"
          alt="Wom challenge logo"
          style={{ width: 30, height: 30 }}
        />
      </Tooltip>
    );
  } else if (source === 'meta_adv' || source === 'meta' || source?.includes('facebook')) {
    source_icon = (
      <Tooltip title="Meta ADV">
        <img
          src="https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/common_icons/meta_adv_icon.png"
          alt="Meta ADV logo"
          style={{ width: 30, height: 30 }}
        />
      </Tooltip>
    );
  } else if (source === 'Jobbydoo') {
    source_icon = (
      <Tooltip title="Jobbydoo">
        <img
          src="https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/common_icons/jobbydoo.png"
          alt="Jobbydoo logo"
          style={{ width: 30, height: 30 }}
        />
      </Tooltip>
    );
  } else if (source === 'beBee') {
    source_icon = (
      <Tooltip title="BeBee">
        <img
          src="https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/common_icons/bebee.jpg"
          alt="Bebee logo"
          style={{ width: 30, height: 30 }}
        />
      </Tooltip>
    );
  } else if (source === 'Hirematic') {
    source_icon = (
      <Tooltip title="Hirematic">
        <img
          src="https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/common_icons/hirematic.png"
          alt="Hirematic logo"
          style={{ width: 30, height: 30 }}
        />
      </Tooltip>
    );
  } else {
    source_icon = (
      <Tooltip title={`Fonte sconosciuta: ${source}`}>
        <span>
          <Iconify icon="bx:question-mark" sx={iconStyle} />
        </span>
      </Tooltip>
    );
  }
  return source_icon;
}
