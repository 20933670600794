import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useOrganizationService } from 'service';
import { OrganizationDiscrepancy, SlotDiscrepanciesAlertParams } from 'service/organizations';

enum SlotDiscrepanciesKeys {
  discrepanciesBetweenSlotsAndActiveJobPositions = 'fetch-discrepancies-between-slots-and-active-job-positions',
  organizationsWithSlotAndJpDiscrepancies = 'get-organizations-discrepancies-between-slots-and-active-job-positions'
}
export const useGetDiscrepanciesBetweenSlotsAndActiveJobPositionsQuery = ({
  organizationId
}: SlotDiscrepanciesAlertParams) => {
  const organizationServices = useOrganizationService();

  return useQuery({
    queryKey: [
      SlotDiscrepanciesKeys.discrepanciesBetweenSlotsAndActiveJobPositions,
      organizationId
    ],
    queryFn: async () =>
      (
        await organizationServices.getDiscrepanciesBetweenSlotsAndActiveJobPositions({
          organizationId
        })
      ).data,
    enabled: !!organizationId,
    retry: 3
  });
};
export const useGetDiscrepanciesBetweenSlotsAndActiveJobPositions = ({
  organizationId
}: SlotDiscrepanciesAlertParams) => {
  const discrepanciesData = useQueryClient().getQueryData([
    SlotDiscrepanciesKeys.discrepanciesBetweenSlotsAndActiveJobPositions,
    organizationId
  ]) as OrganizationDiscrepancy;
  return {
    hasDiscrepancies: discrepanciesData?.active_job_positions !== discrepanciesData?.active_slots,
    data: discrepanciesData
  };
};

// organizations slot and jp discrepancies query
export const useFetchOrganizationsWithSlotAndJpDiscrepanciesQuery = ({
  csmId
}: SlotDiscrepanciesAlertParams) => {
  const organizationServices = useOrganizationService();

  return useQuery({
    queryKey: [SlotDiscrepanciesKeys.organizationsWithSlotAndJpDiscrepancies, csmId],
    queryFn: async () =>
      (
        await organizationServices.getOrganizationsWithSlotAndJpDiscrepancies({
          csmId
        })
      ).data,
    enabled: !!csmId,
    retry: 3
  });
};
export const useOrganizationsDiscrepanciesBetweenSlotsAndActiveJobPositions = ({
  csmId
}: SlotDiscrepanciesAlertParams) =>
  useQueryClient().getQueryData([
    SlotDiscrepanciesKeys.organizationsWithSlotAndJpDiscrepancies,
    csmId
  ]) as OrganizationDiscrepancy[];
