import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { OrderAndPagination as OrderAndPaginationType } from '@restworld/utility-types';
import { OrderAndPaginationDefaultValue } from 'constants/common';

// ----------------------------------------------------------------------

function useSidebarFilters<FilterType>(initialFilters: FilterType, defaultTab?: 0 | 1 | 2) {
  const [searchParams] = useSearchParams();
  const searchFilterParam =
    searchParams.get('filter') && JSON.parse(searchParams.get('filter') as any);
  const searchPaginationParam =
    searchParams.get('pagination') && JSON.parse(searchParams.get('pagination') as any);
  const activeTab = !!searchParams.get('activeTab')
    ? parseInt(searchParams.get('activeTab') || '0')
    : defaultTab || 0;

  const [filters, setFilters] = useState<FilterType>(
    (searchFilterParam as FilterType) || initialFilters
  );
  const [tab, setTab] = useState(activeTab);
  const [orderAndPagination, setOrderAndPaginationState] = useState<OrderAndPaginationType>(
    (searchPaginationParam as any) || OrderAndPaginationDefaultValue
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!filters) return;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('filter', JSON.stringify(filters));
    searchParams.set('pagination', JSON.stringify(orderAndPagination));
    searchParams.set('activeTab', tab.toString());
    navigate({ search: searchParams.toString() }, { replace: true });
  }, [filters, orderAndPagination, tab, location.search, navigate]);

  const onFiltersApplied = useCallback((newFilters: any) => setFilters(newFilters), []);
  const onTabChange = useCallback(
    (tabId: number, myWork?: boolean, assistanceRequired?: boolean) => {
      setTab(tabId);
      setOrderAndPaginationState((orderAndPaginationState) => ({
        ...OrderAndPaginationDefaultValue,
        rowsPerPage: orderAndPaginationState.rowsPerPage
      }));
      onFiltersApplied({
        ...filters,
        myWork: myWork || undefined,
        assistance_required: assistanceRequired || undefined
      });
    },
    [onFiltersApplied, filters]
  );

  return {
    activeTab: tab,
    filters,
    onFiltersApplied,
    orderAndPagination,
    setOrderAndPaginationState,
    setActiveTab: onTabChange
  };
}

export default useSidebarFilters;
