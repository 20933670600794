import { Edit as EditIcon, Warning, WarningAmber } from '@mui/icons-material';
import {
  Button,
  ButtonProps,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack
} from '@mui/material';
import useComponentStatus from 'hooks/useComponentStatus';
import useGlobalOptionsFetchingContext from 'hooks/useGlobalOptionsFetchingContext';
import { WorkerActionType } from '../@types/actions';
import { useMemo, useState } from 'react';
import { useWorkersService } from '@restworld/data-services';
import { EntityId, Status, Worker } from '@restworld/utility-types';
import { Tooltip } from '@mui/material';
import { formatTime } from './../../src/utils/common';
import { differenceInDays } from 'date-fns';

type ContextType = 'update';
const contexts: ContextType[] = ['update'];
const componentStatusOptions = {
  update: { showNotificationOnSuccess: true }
};

interface Props extends ButtonProps {
  workerId: EntityId;
  researchStatus?: Status;
  researchStatusLastUpdated: string;
  dispatch?: (action: WorkerActionType, payload: Partial<Worker>) => void;
}

export default function WorkerResearchStatus({
  workerId,
  researchStatus,
  researchStatusLastUpdated,
  dispatch,
  ...rest
}: Props) {
  const workerService = useWorkersService();
  const { status, updateStatus } = useComponentStatus(contexts, componentStatusOptions);
  const { statusOptions } = useGlobalOptionsFetchingContext({
    statusPrefix: ['worker_research_status']
  });

  const [updatingStatus, setUpdatingStatus] = useState<Status | null>(null);
  const [statusMenu, setStatusMenu] = useState<{ open: boolean; anchorEl: Element | null }>({
    open: false,
    anchorEl: null
  });

  let statusLabel;
  let labelColor: 'inherit' | 'error' | 'success' | 'warning';
  switch (researchStatus?.key) {
    case 'worker_research_status_not_searching':
      labelColor = 'error';
      statusLabel = 'Non cerca';
      break;
    case 'worker_research_status_employed_looking_for_change':
      labelColor = 'warning';
      statusLabel = 'Impiegato';
      break;
    case 'worker_research_status_looking_without_hurry':
      labelColor = 'success';
      statusLabel = 'Senza fretta';
      break;
    case 'worker_research_status_looking_urgently':
      labelColor = 'success';
      statusLabel = 'Urgente';
      break;
    default:
      labelColor = 'inherit';
      statusLabel = '';
  }

  const handleOpenMenu = (e: any) => {
    setStatusMenu({ open: true, anchorEl: e.currentTarget });
  };
  const handleCloseMenu = () => {
    setStatusMenu({ open: false, anchorEl: null });
  };

  const handleChangeResearchStatus = (s: Status) => {
    if (status?.update.status !== 'IDLE') return;
    const context: ContextType = 'update';
    updateStatus(context, 'LOADING');
    setUpdatingStatus(s);
    workerService
      .updateResearchStatus({ workerId, statusKey: s.key })
      .then((res) => {
        updateStatus(context, 'SUCCESS');
        setUpdatingStatus(null);
        !!dispatch &&
          dispatch('update_research_status', {
            user_id: workerId,
            research_status: s,
            research_status_last_updated: res.data.research_status_last_updated
          });
        handleCloseMenu();
      })
      .catch((err) => {
        updateStatus(context, 'ERROR', err?.data?.error);
        setUpdatingStatus(null);
      });
  };

  const showIcon = useMemo(() => {
    if (!researchStatus?.key) return false;

    const lastUpdate = differenceInDays(new Date(), new Date(researchStatusLastUpdated));
    if (researchStatus.key === 'worker_research_status_not_searching' && lastUpdate > 30) {
      return true;
    }
    return false;
  }, [researchStatusLastUpdated, researchStatus?.key]);

  return (
    <>
      <Button
        color={labelColor}
        endIcon={<EditIcon fontSize="small" sx={!!researchStatus ? {} : { color: 'grey.500' }} />}
        // fullWidth
        // sx={{ justifyContent: 'right' }}
        onClick={handleOpenMenu}
        {...rest}
      >
        <Tooltip
          title={'Ultimo aggiornamento il: ' + formatTime({ dateTime: researchStatusLastUpdated })}
        >
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {showIcon && <WarningAmber />}
            <span>{statusLabel}</span>
          </Stack>
        </Tooltip>
      </Button>
      <Menu open={statusMenu.open} onClose={handleCloseMenu} anchorEl={statusMenu.anchorEl}>
        {statusOptions?.worker_research_status?.map((s) => (
          <MenuItem key={s.id} onClick={() => handleChangeResearchStatus(s)}>
            {status?.update.status === 'LOADING' && updatingStatus?.id === s.id && (
              <ListItemIcon>
                <CircularProgress thickness={2} color="inherit" size={20} />
              </ListItemIcon>
            )}
            <ListItemText>{s.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
