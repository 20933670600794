import { useCallback, useEffect } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import useComponentStatus from 'hooks/useComponentStatus';
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { useWorkerJobPositionService } from 'service';
import { KlaaryoConversation } from 'service/worker';
import {
  klaaryoConversationStatusMenuItems,
  PrescreeningQuestionAnswersView,
  RedirectToKlaaryoConversation
} from './KlaaryoConversationModal';
import { LoadingButton } from '@mui/lab';

type ContextType = 'klaaryo_conversation';
const contexts: ContextType[] = ['klaaryo_conversation'];
const statusContextOptions = {
  [contexts[0]]: { showNotificationOnSuccess: true }
};

type Props = {
  onClose: () => void;
  conversationId?: number;
  refresh?: () => void;
  klaaryoConversation?: KlaaryoConversation;
};

export default function KlaaryoConversationContentUpdate({
  refresh,
  conversationId,
  onClose,
  klaaryoConversation
}: Props) {
  const tempWorkerJobPositionService = useWorkerJobPositionService();
  const { updateStatus, status } = useComponentStatus(contexts, statusContextOptions);
  const methods = useForm<Partial<KlaaryoConversation>>({
    defaultValues: {}
  });
  const { register, reset, handleSubmit, control } = methods;

  useEffect(() => {
    reset(klaaryoConversation);
  }, [klaaryoConversation, reset]);

  const onSubmit: SubmitHandler<Partial<KlaaryoConversation>> = useCallback(
    (data) => {
      const context: ContextType = 'klaaryo_conversation';
      updateStatus(context, 'LOADING');
      tempWorkerJobPositionService
        .updateKlaaryoConversation(data)
        .then((res) => {
          updateStatus(context, 'SUCCESS');
          refresh && refresh();
          onClose();
        })
        .catch((error) => {
          console.error({ error });
          updateStatus(context, 'ERROR', error?.data?.error);
        });
    },
    [tempWorkerJobPositionService, updateStatus, refresh, onClose]
  );

  return (
    <FormProvider {...methods}>
      <form>
        <DialogContent sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="conversation_status"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormControl sx={{ mt: 1, width: '60%' }}>
                    <InputLabel id="select-label">Stato</InputLabel>
                    <Select
                      value={value || 'null'}
                      labelId="select-label"
                      label="Stato"
                      onChange={(e) => {
                        if (e.target.value === 'null') onChange(null);
                        else onChange(e.target.value);
                      }}
                    >
                      {klaaryoConversationStatusMenuItems?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6} container justifyContent="end">
              <RedirectToKlaaryoConversation conversationId={conversationId} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                multiline
                label="Summary"
                minRows={2}
                fullWidth
                {...register('summary')}
              />
            </Grid>
            <Grid item xs={12}>
              <PrescreeningQuestionAnswers />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="inherit">
            Indietro
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            loading={status?.klaaryo_conversation?.status === 'LOADING'}
          >
            Aggiorno
          </LoadingButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
}

const PrescreeningQuestionAnswers = () => {
  const { watch } = useFormContext();
  const conversationSummaryObject = watch('conversation_summary_object');

  return <PrescreeningQuestionAnswersView conversationSummaryObject={conversationSummaryObject} />;
};
