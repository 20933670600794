import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogTitle,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import {
  KlaaryoConversationStatus,
  KlaaryoConversation as KlaaryoConversationType
} from 'service/worker';
import { useWorkerJobPositionService } from 'service';
import { Stack } from '@mui/material';
import KlaaryoConversationContentUpdate from './KlaaryoConversationContentUpdate';
import KlaaryoConversationView from './KlaaryoConversationView';
import {
  ArrowForward,
  ArrowForwardOutlined,
  Edit,
  ExpandMore,
  MoreVert
} from '@mui/icons-material';

export const klaaryoConversationStatusMenuItems: {
  value: KlaaryoConversationStatus | 'null';
  label: string;
}[] = [
  { value: 'null', label: '' },
  { value: 'to_contact', label: 'Da contattare' },
  { value: 'contacted', label: 'Contattato' },
  { value: 'replied', label: 'Ha risposto' },
  { value: 'in_progress', label: 'In corso' },
  { value: 'not_interested', label: 'Non interessato' },
  { value: 'human_action_required', label: 'Supporto necessario' },
  { value: 'unresponsive', label: 'Non risponde' },
  { value: 'conversation_closed', label: 'Chiuso' }
];

type Props = {
  isOpen: boolean;
  onClose: () => void;
  jobApplicationId?: string;
  refresh?: () => void;
  jobPositionSlug?: string;
};

export default function KlaaryoConversationModal({
  isOpen,
  onClose,
  jobApplicationId,
  refresh,
  jobPositionSlug
}: Props) {
  const tempWorkerJobPositionService = useWorkerJobPositionService();
  const [klaaryoConversation, setKlaaryoConversation] = useState<KlaaryoConversationType>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

  const fetchConversation = useCallback(() => {
    jobApplicationId &&
      tempWorkerJobPositionService
        .fetchKlaaryoConversation({ jobApplicationId })
        .then((res) => {
          setKlaaryoConversation(res.data);
        })
        .catch((error) => console.error({ error }));
  }, [jobApplicationId, tempWorkerJobPositionService]);

  useEffect(() => {
    fetchConversation();
  }, [fetchConversation]);

  const handleMenuEditClick = useCallback(() => {
    setIsEditMode((prev) => !prev);
    setIsMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const openMenu = useCallback((e: any) => {
    setIsMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const localCloseHandler = useCallback(() => {
    setIsEditMode(false);
    onClose();
  }, [onClose]);

  const localRefresh = useCallback(() => {
    fetchConversation();
    refresh && refresh();
  }, [fetchConversation, refresh]);

  const handleOpenJPLanding = useCallback(() => {
    window.open(`https://restworld.it/posizione/${jobPositionSlug}`, '_blank');
  }, [jobPositionSlug]);

  return (
    <>
      <Menu open={isMenuOpen} onClose={closeMenu} anchorEl={menuAnchorEl}>
        <MenuItem onClick={handleMenuEditClick}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Modifica</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenJPLanding}>
          <ListItemIcon>
            <ArrowForward />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Apri annuncio su sito</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={isOpen} onClose={localCloseHandler} fullWidth maxWidth="md">
        <DialogTitle>
          <Stack justifyContent="space-between" flexDirection="row">
            <Typography variant="h5">Conversazione agente AI</Typography>
            <IconButton onClick={(e) => openMenu(e)}>
              <MoreVert />
            </IconButton>
          </Stack>
        </DialogTitle>
        {isEditMode ? (
          <KlaaryoConversationContentUpdate
            onClose={localCloseHandler}
            conversationId={klaaryoConversation?.klaaryo_conversation_id}
            refresh={localRefresh}
            klaaryoConversation={klaaryoConversation}
          />
        ) : (
          <KlaaryoConversationView
            conversationId={klaaryoConversation?.klaaryo_conversation_id}
            onClose={localCloseHandler}
            klaaryoConversation={klaaryoConversation}
          />
        )}
      </Dialog>
    </>
  );
}

export const PrescreeningQuestionAnswersView = ({
  conversationSummaryObject
}: {
  conversationSummaryObject: any;
}) => {
  const preScreeningQuestions: any[] = useMemo(() => {
    return conversationSummaryObject?.questions;
  }, [conversationSummaryObject]);

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="research" id="research-header">
          <Typography sx={{ textTransform: 'uppercase', color: 'grey' }} variant="subtitle2">
            Risposte alle domande di preselezione
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!!preScreeningQuestions && preScreeningQuestions?.length > 1 ? (
            preScreeningQuestions?.map((qAns, index) => {
              return (
                <Stack key={index} sx={{ mb: 1 }}>
                  <Typography variant="subtitle1">{qAns?.question}</Typography>
                  <Typography variant="body1">{qAns?.reply}</Typography>
                </Stack>
              );
            })
          ) : (
            <Typography variant="body2" fontStyle="italic">
              La conversazione con l'agente AI non è ancora conclusa
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export const RedirectToKlaaryoConversation = ({ conversationId }: { conversationId?: number }) => {
  return (
    <>
      {conversationId && (
        <Link
          underline="always"
          href={`https://app.klaaryo.com/inbox?conversation=${conversationId}`}
          target="_blank"
        >
          <Stack flexDirection="row" gap={1}>
            Apri conversazione <ArrowForwardOutlined />
          </Stack>
        </Link>
      )}
    </>
  );
};
