import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';
import { Button } from '@mui/material';
import { DialogActions } from '@mui/material';
import { DialogContent, DialogTitle } from '@mui/material';
import { EntityId } from '@restworld/utility-types';
import useComponentStatus from 'hooks/useComponentStatus';
import { useWorkerJobPositionsService } from '@restworld/data-services';
import Dialog from './Dialog';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  jobPositionId: EntityId;
  refresh?: () => void;
  onSuccess?: () => void;
}

type StatusContextType = 'clean_pending_applications';
const contexts: StatusContextType[] = ['clean_pending_applications'];

const statusContextOptions = {
  [contexts[0]]: { showNotificationOnSuccess: true }
};

export default function CleanPendingApplications({
  isOpen,
  handleClose,
  jobPositionId,
  refresh,
  onSuccess
}: Props) {
  const workerJobPositionService = useWorkerJobPositionsService();
  const { status, updateStatus } = useComponentStatus(contexts, statusContextOptions);

  const handleCleanPendingApplications = () => {
    const context: StatusContextType = 'clean_pending_applications';
    updateStatus(context, 'LOADING');
    workerJobPositionService
      .cleanPendingApplications(jobPositionId)
      .then((res) => {
        updateStatus(context, 'SUCCESS', 'Candidature pulite con successo!');
        handleClose();
        refresh && refresh();
        onSuccess && onSuccess();
      })
      .catch((err) => {
        updateStatus(context, 'ERROR', err?.data?.error);
      });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Hai finito la selezione per questa rosa?</DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        Puoi spostare tutte le candidature rimaste in panchina come <b>Interessati</b> o come{' '}
        <b>Posizione proposta</b> nello stato di <b>Idoneo non selezionato</b>.
        <Alert severity="warning" sx={{ mt: 1 }}>
          Cliccando sì, tutti i worker ancora nel flusso di selezione saranno spostati in "Idoneo
          non selezionato". L'azione <b>non è reversibile</b>.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={handleClose}>
          No
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleCleanPendingApplications}
          loading={status?.clean_pending_applications?.status === 'LOADING'}
        >
          Sì
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
