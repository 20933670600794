import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { DialogActions, FormControl, Grid } from '@mui/material';
import { DialogContent, DialogTitle } from '@mui/material';
import CsmAssignation from 'components/CsmAssignation';
import Dialog from 'components/Dialog';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAccountService } from 'service';
import { FlexibleSlot } from 'service/accounts';

interface Props {
  isOpen: boolean;
  flexibleSlot: FlexibleSlot;
  onClose: () => void;
  refreshSlots: () => void;
}

export default function EditFlexibleSlot({ isOpen, flexibleSlot, onClose, refreshSlots }: Props) {
  const accountsService = useAccountService();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm<Partial<FlexibleSlot>>({
    defaultValues: flexibleSlot
  });

  useEffect(() => {
    reset(flexibleSlot);
  }, [flexibleSlot, reset]);

  const onSubmit = (data: Partial<FlexibleSlot>) => {
    setIsLoading(true);
    accountsService
      .updateFlexibleSlot({ id: flexibleSlot.id, data })
      .then((res) => {
        setIsLoading(false);
        refreshSlots();
        onClose();
      })
      .catch((err) => console.error(err));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Modifica slot</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container justifyContent="center" rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="activated_by"
                  render={({ field: { value, onChange } }) => (
                    <CsmAssignation
                      assigned_to={value as string}
                      error={!!errors.activated_by}
                      isEditMode={true}
                      updateEntity={(csmId) => {
                        onChange(csmId);
                      }}
                      label="Attivato da"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={onClose}>
            Annulla
          </Button>
          <LoadingButton loading={isLoading} variant="contained" type="submit">
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
