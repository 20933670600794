import { atom } from 'jotai';

import { WorkerFilterType } from '@restworld/data-services';

// employer listing
export type TEmployerSidebarFilter = {
  csm?: string;
  cities?: string[];
  openPositions?: boolean;
  myWork?: boolean;
};
const initialEmployerSidebarFilterData = {
  myWork: true
};
export const employerSidebarFilterAtom = atom<TEmployerSidebarFilter>(
  initialEmployerSidebarFilterData
);
employerSidebarFilterAtom.debugLabel = 'employerSidebarFilterAtom';
// job position listing
export type TJobPositionSidebarFilter = {
  csm?: string;
  tam?: string;
  jr?: string;
  occupation_id?: string;
  status_key?: string[];
  'advance_payment_paid?'?: boolean;
  priority?: number[];
  myWork?: boolean;
  assistance_required?: boolean;
  junior_recruiter?: string;
};
const initalJobPositionSidebarFilterData = {
  myWork: true
};
export const jobPositionSidebarFilterAtom = atom<TJobPositionSidebarFilter>(
  initalJobPositionSidebarFilterData
);
jobPositionSidebarFilterAtom.debugLabel = 'jobPositionSidebarFilterAtom';
// restaurant listing
export type TRestaurantSidebarFilter = {
  type?: string;
  csm?: string;
  cities?: string[];
  openPositions?: boolean;
  myWork?: boolean;
};
const initialRestaurantSidebarFilterData = {
  myWork: true
};
export const restaurantSidebarFilterAtom = atom<TRestaurantSidebarFilter>(
  initialRestaurantSidebarFilterData
);
restaurantSidebarFilterAtom.debugLabel = 'restaurantSidebarFilterAtom';
// worker listing
export const initialWorkerListingFilterData = {
  age_from: undefined,
  age_to: undefined,
  transportation_options: [],
  max_restaurant_distance: undefined,
  experience: [],
  domain_interest: [],
  research_status_key: [
    'worker_research_status_employed_looking_for_change',
    'worker_research_status_looking_without_hurry',
    'worker_research_status_looking_urgently'
  ],
  not_in_other_shortlists: undefined,
  sex: [],
  occupations: [],
  languages: [],
  skills: [],
  job_type: [],
  job_application_status: 'worker_jp_interested',
  status_key: undefined,
  displacement_availability: [],
  is_contacted: undefined,
  similar_job_positions_filter: undefined,
  limit_similar_job_positions: 20,
  semantic_search: undefined,
  exclude_same_employer_similar_job_position: true
};
export const workerSidebarFilterAtom = atom<
  WorkerFilterType & {
    address?: string;
    address_lon?: number;
    address_lat?: number;
    displacement_preferences?: string[];
  }
>(initialWorkerListingFilterData);
workerSidebarFilterAtom.debugLabel = 'workerSidebarFilterAtom';
