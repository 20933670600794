import * as React from 'react';
import { Alert } from '@mui/material';
import { WarningAmberOutlined } from '@mui/icons-material';

import { SlotDiscrepanciesAlertParams } from 'service/organizations';
import { Spacer } from 'styles/common';

import {
  useGetDiscrepanciesBetweenSlotsAndActiveJobPositions,
  useGetDiscrepanciesBetweenSlotsAndActiveJobPositionsQuery
} from './use-slot-jp-discrepancies-queries';

const SlotDiscrepanciesAlert: React.FunctionComponent<SlotDiscrepanciesAlertParams> = ({
  organizationId
}) => {
  const { hasDiscrepancies, data } = useGetDiscrepanciesBetweenSlotsAndActiveJobPositions({
    organizationId
  });
  useGetDiscrepanciesBetweenSlotsAndActiveJobPositionsQuery({ organizationId });
  return hasDiscrepancies ? (
    <Alert
      sx={{ marginTop: '24px !important', marginBottom: '24px !important', maxWidth: '50%' }}
      severity="warning"
      icon={<WarningAmberOutlined />}
      variant="standard"
    >
      Questo employer ha {data?.active_job_positions} posizioni lavorative online con{' '}
      {data?.active_slots} slot attivi
    </Alert>
  ) : (
    <Spacer size="md" />
  );
};

export default SlotDiscrepanciesAlert;
