import { useCallback } from 'react';
import { useSetAtom } from 'jotai';
import { useAccountService } from 'service';
import { setFlexibleSlotsAtom, setFlexibleSlotsCountAtom } from '../global-state';

type Props = {
  organizationId?: string;
  limit: number;
  offset: number;
};

export function useFlexibleSlots({ organizationId, limit, offset }: Props) {
  const tempAccountsService = useAccountService();
  const setFlexibleSlots = useSetAtom(setFlexibleSlotsAtom);
  const setFlexibleSlotsCount = useSetAtom(setFlexibleSlotsCountAtom);

  const fetchFlexibleSlots = useCallback(() => {
    organizationId &&
      tempAccountsService
        .listFlexibleSlots({ organizationId: organizationId, limit, offset })
        .then((res) => {
          setFlexibleSlots(res.data?.data);
          setFlexibleSlotsCount(res.data?.hits || 0);
        })
        .catch((error) => {
          console.error({ error });
        });
  }, [tempAccountsService, organizationId, setFlexibleSlots, limit, offset, setFlexibleSlotsCount]);

  return {
    fetchFlexibleSlots
  };
}
