import { Employer, Company, Organization } from '@restworld/utility-types';
import { atom } from 'jotai';
import { FlexibleSlot } from 'service/accounts';
import { OrgnaizationStructuredNote } from 'service/organizations';

export type OrganizationUpdated = Organization & {
  structured_note?: OrgnaizationStructuredNote;
};

export const employerDetailsAtom = atom<Employer | undefined>(undefined);
export const organizationDetailsAtom = atom<OrganizationUpdated | undefined>(undefined);
export const companiesDetailsAtom = atom<Company[] | undefined>(undefined);

// create a new set atom for the employer details
export const setEmployerDetailsAtom = atom(null, (_get, set, update: Employer) => {
  set(employerDetailsAtom, update);
});
export const setOrganizationDetailsAtom = atom(null, (_get, set, update: Organization) => {
  set(organizationDetailsAtom, update);
});
export const setCompaniesDetailsAtom = atom(null, (_get, set, update: Company[]) => {
  set(companiesDetailsAtom, update);
});

export const restaurantsContactAtom = atom((get) => {
  const employer = get(employerDetailsAtom);
  const contacts = employer?.contacts || [];
  const restaurantEmployerIds = new Set(
    (employer?.restaurants && employer?.restaurants.map((r: any) => r.employer_id)) || []
  );
  return contacts.filter((c) => restaurantEmployerIds.has(c.employer_id));
});

export const employerDetailLoadingAtom = atom<boolean>(false);

export const setEmployerDetailLoadingAtom = atom(null, (_get, set, update: boolean) => {
  set(employerDetailLoadingAtom, update);
});

export const organizationDetailLoadingAtom = atom<boolean>(false);

export const setOrganizationDetailLoadingAtom = atom(null, (_get, set, update: boolean) => {
  set(setOrganizationDetailLoadingAtom, update);
});

export const flexibleSlotsAtom = atom<FlexibleSlot[]>([]);
export const setFlexibleSlotsAtom = atom(null, (_get, set, update: FlexibleSlot[]) => {
  set(flexibleSlotsAtom, update);
});
export const flexibleSlotsCountAtom = atom<number>(0);
export const setFlexibleSlotsCountAtom = atom(null, (_get, set, update: number) => {
  set(flexibleSlotsCountAtom, update);
});
