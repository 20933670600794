import {
  TableContainer,
  TableCell,
  TableRow,
  Box,
  Typography,
  Table,
  TableBody,
  TableHead
} from '@mui/material';
import { StripeSubscription, StripeSubscriptionStatus, Company } from '@restworld/utility-types';
import Label, { LabelColor } from './Label';
import { fromUnixTime, format, differenceInDays } from 'date-fns';
import { it } from 'date-fns/locale';

export const SubscriptionDeadline = ({ deadline }: { deadline: Date }) => {
  const deadlineString = format(deadline, 'dd/MM/yyyy', { locale: it });

  let color: LabelColor = 'default';
  if (differenceInDays(deadline, new Date()) < 0) {
    color = 'error';
  }
  if (differenceInDays(deadline, new Date()) <= 3) {
    color = 'warning';
  }

  return <Label color={color}>{deadlineString}</Label>;
};

const formatDateFromUnixTime = (unixTime: number) => {
  return format(fromUnixTime(unixTime), 'dd/MM/yyyy', {
    locale: it
  });
};

// Subscription status label
const SubscriptionStatusLabel = ({
  status,
  toBeCanceled,
  cancelAt
}: {
  status: StripeSubscriptionStatus;
  toBeCanceled?: boolean;
  cancelAt?: number;
}) => {
  let color: LabelColor = 'default';
  let label: string = status;

  if (status === 'active') {
    color = 'success';
    label = 'Attivo';
  }
  if (status === 'canceled') {
    color = 'error';
    label = 'Cancellato';
  }
  if (status === 'incomplete') {
    color = 'warning';
    label = 'Incompleto';
  }
  if (status === 'incomplete_expired') {
    color = 'error';
    label = 'Incompleto scaduto';
  }
  if (status === 'past_due') {
    color = 'error';
    label = 'Scaduto';
  }
  if (status === 'trialing') {
    color = 'warning';
    label = 'In prova';
  }
  if (status === 'unpaid') {
    color = 'error';
    label = 'Non pagato';
  }

  return (
    <Label color={toBeCanceled ? 'warning' : color}>
      {label}
      {toBeCanceled ? ` - Si annullerà il ${formatDateFromUnixTime(cancelAt!)}` : ''}
    </Label>
  );
};

export type StripeSubscriptionUpdated = StripeSubscription & {
  customer?: string;
  cancel_at?: number;
};

type Props = {
  activeSubscriptions: StripeSubscriptionUpdated[] | null;
  companies?: Company[];
  isOrganization?: boolean;
};

export default function SubscriptionTable({
  activeSubscriptions,
  isOrganization,
  companies
}: Props) {
  if (!!!activeSubscriptions || (!!activeSubscriptions && activeSubscriptions.length === 0))
    return null;

  return (
    <Box sx={{ py: 2 }}>
      <Typography variant="h6">Lista abbonamenti</Typography>
      <TableContainer sx={{ bgcolor: 'white', mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              {isOrganization && <TableCell>Azienda</TableCell>}
              <TableCell>Prodotto</TableCell>
              <TableCell>Avviato il</TableCell>
              <TableCell>Prossima data di pagamento</TableCell>
              <TableCell>Slot attivi</TableCell>
              <TableCell>Stato</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeSubscriptions?.map((sub, i) => {
              const toBeCanceled = sub.cancel_at_period_end || !!sub?.cancel_at;
              const showNextPaymentDate =
                sub?.cancel_at && sub?.cancel_at <= sub?.current_period_end ? false : true;
              return (
                <TableRow key={i} sx={{ color: 'white' }}>
                  <TableCell>{i + 1}</TableCell>
                  {isOrganization && companies && (
                    <TableCell>
                      {companies?.find((c) => c.stripe_customer_id === sub.customer)?.name}
                    </TableCell>
                  )}
                  <TableCell>{sub.items?.[0]?.product}</TableCell>
                  <TableCell>{formatDateFromUnixTime(sub.start_date)}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {showNextPaymentDate && (
                      <SubscriptionDeadline deadline={fromUnixTime(sub.current_period_end)} />
                    )}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{sub.items?.[0]?.quantity}</TableCell>
                  <TableCell>
                    <SubscriptionStatusLabel
                      status={sub.status}
                      toBeCanceled={toBeCanceled}
                      cancelAt={sub?.cancel_at}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
