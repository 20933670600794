import * as React from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { confirmationDialogVisibleAtom } from './flexible-slots.atoms';
import { useAccountService } from 'service';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';
import { SubscriptionDeadline } from 'components/SubscriptionTable';
import Label, { LabelColor } from 'components/Label';
import ActionConfirmationModal from 'components/ActionConfirmationModal';
import useComponentStatus from 'hooks/useComponentStatus';
import { useFlexibleSlots } from '../hooks/use-flexible-slots';
import { flexibleSlotsAtom, flexibleSlotsCountAtom } from '../global-state';
import { MoreVert } from '@mui/icons-material';
import { FlexibleSlot, InvoicePaymentType } from 'service/accounts';
import FlexibleSlotActions from './felxible-slot-actions';

interface FlexibleSlotTableData {
  slot_purchase_date: string;
  total_slots_purchased: number;
  slot_id: string;
  slot_status: string;
  slot_activation_date: string;
  slot_expiration_date: string;
  slot_actions: string;
}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof FlexibleSlotTableData;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'slot_purchase_date',
    numeric: false,
    disablePadding: false,
    label: 'Acquistato il'
  },
  {
    id: 'slot_activation_date',
    numeric: false,
    disablePadding: false,
    label: 'Attivato il'
  },
  {
    id: 'slot_expiration_date',
    numeric: false,
    disablePadding: false,
    label: 'Valido fino al'
  },
  {
    id: 'slot_status',
    numeric: false,
    disablePadding: false,
    label: 'Stato'
  },
  {
    id: 'slot_actions',
    numeric: false,
    disablePadding: false,
    label: ''
  }
];

type ContextType = 'activate_slot' | 'flexible_slots';
const contexts: ContextType[] = ['activate_slot', 'flexible_slots'];
const statusContextOptions = {
  activate_slot: { showNotificationOnSuccess: true }
};

interface EnhancedTableProps {
  numSelected?: number;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof FlexibleSlotTableData) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order;
  orderBy?: string;
  rowCount?: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StatusLabel = ({
  status,
  cancelAtPeriodEnd
}: {
  status: string;
  cancelAtPeriodEnd: boolean;
}) => {
  let color: LabelColor = 'default';
  let label = status;
  let tooltip = undefined;

  if (status === 'active') {
    color = 'success';
    label = 'Attivo';
    if (cancelAtPeriodEnd) {
      label = 'Attivo, cancellato al termine';
      tooltip =
        'Lo slot sarà attivo fino alla data di scadenza, dopodiché, il cliente ha chiesto di bloccare la ricerca';
    }
  }
  if (status === 'idle') {
    color = 'default';
    label = 'Inattivo';
  }
  if (status === 'expired') {
    color = 'error';
    label = 'Scaduto';
  }

  return (
    <Tooltip title={tooltip}>
      <Label color={color}>{label}</Label>
    </Tooltip>
  );
};

type Props = {
  organizationId?: string;
  parentPage?: string;
};

export default function FlexibleSlotsTable({ organizationId, parentPage }: Props) {
  const tempAccountsService = useAccountService();
  const { status, updateStatus } = useComponentStatus(contexts, statusContextOptions);

  //  Action menu state
  const [isActionMenuOpen, setIsActionMenuOpen] = React.useState<boolean>(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [flexibleSlotActionMenu, setFlexibleSlotActionMenu] = React.useState<FlexibleSlot | null>(
    null
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const flexibleSlots = useAtomValue(flexibleSlotsAtom);
  const flexibleSlotsCount = useAtomValue(flexibleSlotsCountAtom);

  const { fetchFlexibleSlots } = useFlexibleSlots({
    organizationId,
    limit: rowsPerPage,
    offset: rowsPerPage * page
  });

  React.useEffect(() => fetchFlexibleSlots(), [fetchFlexibleSlots]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setSlotActivationConfirmationDialogVisible = useSetAtom(confirmationDialogVisibleAtom);
  const isSlotActivationConfirmationOpen = useAtomValue(confirmationDialogVisibleAtom);

  const handleActivateFlexibleSlot = React.useCallback(() => {
    const context: ContextType = 'activate_slot';
    updateStatus(context, 'LOADING');
    tempAccountsService
      .activateFlexibleSlot({ organizationId })
      .then((res) => {
        updateStatus(context, 'SUCCESS', 'Flex Slot attivato con successo');
        setSlotActivationConfirmationDialogVisible(false);
        fetchFlexibleSlots();
      })
      .catch((error) => {
        console.error({ error });
        updateStatus(context, 'ERROR', "Errore durante l'attivazione dello slot");
      });
  }, [
    organizationId,
    tempAccountsService,
    setSlotActivationConfirmationDialogVisible,
    updateStatus,
    fetchFlexibleSlots
  ]);

  const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>, fs: FlexibleSlot) => {
    setActionMenuAnchorEl(event.currentTarget);
    setIsActionMenuOpen(true);
    setFlexibleSlotActionMenu(fs);
  };
  const handleCloseActionMenu = () => {
    setActionMenuAnchorEl(null);
    setIsActionMenuOpen(false);
    setFlexibleSlotActionMenu(null);
  };

  if (flexibleSlotsCount === 0) return null;

  return (
    <Box sx={{ width: '100%' }}>
      {status?.flexible_slots?.status === 'LOADING' ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <ActionConfirmationModal
            isOpen={isSlotActivationConfirmationOpen}
            isLoading={status?.activate_slot?.status === 'LOADING'}
            onClose={() => setSlotActivationConfirmationDialogVisible(false)}
            actionText="Vuoi davvero attivare lo slot per questa organizzazione?"
            action={handleActivateFlexibleSlot}
          />
          <FlexibleSlotActions
            isOpen={isActionMenuOpen}
            anchorEl={actionMenuAnchorEl}
            handleMenuClose={handleCloseActionMenu}
            flexibleSlot={flexibleSlotActionMenu || undefined}
            refreshSlots={fetchFlexibleSlots}
          />
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">Flex Slot, totali: {flexibleSlotsCount}</Typography>
            </Grid>
            <Grid container item xs={6} justifyContent="end">
              <Button
                variant="contained"
                color="primary"
                disabled={parentPage !== 'showOrganization'}
                onClick={() => setSlotActivationConfirmationDialogVisible(true)}
              >
                Attiva un Flex Slot
              </Button>
            </Grid>
          </Grid>
          <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead rowCount={flexibleSlots?.length} />
                <TableBody>
                  {flexibleSlots?.map((row, index) => {
                    if (!row) return null;
                    const isActivateByEmployer = !!row?.activated_by_employer;
                    const activatedByFullName = isActivateByEmployer
                      ? `${row?.activated_by_employer?.user?.name} ${row?.activated_by_employer?.user?.surname}`
                      : `${row?.activated_by_user?.name} ${row?.activated_by_user?.surname}`;
                    const activatedByAvatar = isActivateByEmployer
                      ? row?.activated_by_employer?.picture_url
                      : row?.activated_by_user?.icon_url;

                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell align="left">
                          <Typography variant="body2">
                            {format(
                              utcToZonedTime(new Date(row?.inserted_at || 0), 'Europe/Rome'),
                              'dd/MM/yyyy - HH:mm',
                              { locale: it }
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {row?.activated_at ? (
                            <Stack direction="row" spacing={1} alignItems={'center'}>
                              <Tooltip title={`Attivato da ${activatedByFullName}`}>
                                <Avatar
                                  src={activatedByAvatar}
                                  alt={activatedByFullName}
                                  sx={{ width: 30, height: 30 }}
                                />
                              </Tooltip>
                              <Label color={isActivateByEmployer ? 'warning' : 'info'}>
                                {`${format(
                                  utcToZonedTime(new Date(row?.activated_at || 0), 'Europe/Rome'),
                                  'dd/MM/yyyy - HH:mm',
                                  { locale: it }
                                )} ${isActivateByEmployer ? 'da Employer' : ''}`}
                              </Label>
                              {(row?.invoice?.payment_type as InvoicePaymentType) ===
                                'extra_flexible_slot' && <Label color="primary">Extra week</Label>}
                            </Stack>
                          ) : (
                            'Non ancora attivato'
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {row?.expires_at && (
                            <SubscriptionDeadline deadline={new Date(row?.expires_at)} />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <StatusLabel
                            status={row.status}
                            cancelAtPeriodEnd={row.cancel_at_period_end}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <IconButton onClick={(e) => handleOpenActionMenu(e, row)}>
                            <MoreVert />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={flexibleSlotsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}
    </Box>
  );
}
