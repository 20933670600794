import { Box, BoxProps } from '@mui/material';
import { forwardRef } from 'react';

const JoobleIcon = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return (
    <Box {...props} ref={ref}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="g12">
          <g id="g14">
            <path
              fill="#3482bd"
              id="path20"
              d="M29.786,13.874c-2.142,-10.263 -12.845,-11.162 -21.74,-9.167c5.368,4.644 10.342,6.917 14.444,3.973c-3.448,0.777 -6.858,1.31 -9.572,-2.791c5.667,-2.781 16.789,1.467 16.409,9.318l-0.537,-0.243l0.483,0.685c-0.067,0.327 -0.192,0.596 -0.363,0.806c-0.015,-0 -0.031,-0 -0.047,-0c-0.261,-0.189 -0.219,-0.669 -0.102,-1.222c-0.294,0.564 -0.457,1.107 -0.348,1.611c-1.608,0.751 -2.358,-0.873 -2.602,-1.721c-0.09,0.435 0.215,1.393 0.215,1.393c-1.035,-0.564 -2.178,-1.626 -3.217,-3.224c0.469,1.675 1.83,2.956 3.237,3.563c1.161,0.421 1.659,0.316 1.905,0.311c1.322,-0.029 2.253,-1.293 1.835,-3.292m-2.8,-1.497c-0.377,0.845 -0.202,1.546 0.352,2.147c-0.365,-0.676 -0.452,-1.269 -0.265,-1.781c0.32,-0.028 0.555,0.043 0.719,0.202c-0.084,-0.008 -0.167,0.004 -0.241,0.034c0.1,0.04 0.161,0.121 0.145,0.199c-0.02,0.092 -0.142,0.144 -0.272,0.117c-0.053,-0.011 -0.099,-0.034 -0.135,-0.063c-0.011,0.028 -0.02,0.056 -0.027,0.087c-0.055,0.258 0.117,0.514 0.384,0.57c0.169,0.035 0.335,-0.017 0.448,-0.126c0.023,0.279 0.008,0.61 -0.035,0.988c0.715,-1.529 0.5,-2.418 -1.073,-2.374"
            />
            <path
              id="path22"
              fill="#3482bd"
              d="M20.902,11.101c-3.166,-1.874 -7.324,-2.002 -10.743,-0.36c-1.489,-4.384 -7.237,-0.982 -4.312,2.011c-1.124,0.734 -2.227,1.976 -2.84,5.021c1.608,-4.15 3.575,-4.882 3.902,-4.74c-1.625,-3.021 1.938,-4.06 3.026,-1.369c3.619,-2.048 7.884,-2.233 10.995,-0.342l-0.028,-0.221Z"
            />
            <path
              id="path24"
              fill="#3482bd"
              d="M0.793,19.814c0.661,0.076 0.683,0.088 0.683,0.76l-0,1.619c-0,0.75 -0.022,1.588 -0.089,2.48c-0.033,0.419 -0.099,0.662 -0.176,0.761c-0.077,0.099 -0.418,0.11 -0.694,0.11c-0.198,-0 -0.298,0.11 -0.298,0.331c0,0.363 0.287,0.649 0.452,0.649c0.088,0 0.188,-0.032 0.254,-0.076c0.595,-0.364 1.51,-1.136 1.95,-2.149c0.243,-0.573 0.331,-1.048 0.331,-1.994l0,-3.263c-0.848,0.154 -1.708,0.309 -2.413,0.397l-0,0.375Z"
            />
            <path
              id="path26"
              fill="#61ba52"
              d="M6.876,23.715c-0.563,-0 -0.849,-1.235 -0.849,-2.557c-0,-1.036 0.286,-1.587 0.628,-1.587c0.463,0 0.816,0.706 0.816,2.678c-0,1.179 -0.309,1.466 -0.595,1.466m-0.067,-4.673c-1.355,0 -2.71,0.937 -2.71,2.711c-0,1.256 0.904,2.49 2.633,2.49c1.356,0 2.667,-0.892 2.667,-2.655c0,-1.4 -0.959,-2.546 -2.59,-2.546"
            />
            <path
              id="path28"
              fill="#61ba52"
              d="M12.816,23.715c-0.563,-0 -0.849,-1.235 -0.849,-2.557c-0,-1.036 0.286,-1.587 0.628,-1.587c0.463,0 0.816,0.706 0.816,2.678c-0,1.179 -0.309,1.466 -0.595,1.466m-0.067,-4.673c-1.356,0 -2.711,0.937 -2.711,2.711c-0,1.256 0.904,2.49 2.634,2.49c1.356,0 2.667,-0.892 2.667,-2.655c-0,-1.4 -0.959,-2.546 -2.59,-2.546"
            />
            <path
              id="path30"
              fill="#3482bd"
              d="M18.568,23.682c-0.209,0 -0.386,-0.111 -0.485,-0.386c-0.033,-0.099 -0.067,-0.287 -0.067,-0.463l0,-2.91c0.089,-0.043 0.199,-0.066 0.309,-0.066c0.485,0 1.158,0.331 1.158,2.006c-0,1.169 -0.386,1.819 -0.915,1.819m0.484,-4.64c-0.275,0 -0.462,0.111 -0.661,0.21l-0.375,0.176l0,-3.075c-0.936,0.154 -1.806,0.309 -2.413,0.386l0,0.397c0.662,0.044 0.684,0.055 0.684,0.705l-0,5.973c0.694,0.298 1.322,0.429 1.994,0.429c1.786,0 3.075,-1.189 3.075,-2.832c0,-1.3 -0.948,-2.369 -2.304,-2.369"
            />
            <path
              id="path32"
              fill="#3482bd"
              d="M24.122,22.933l-0,-6.58c-0.937,0.154 -1.808,0.309 -2.413,0.386l-0,0.397c0.66,0.044 0.683,0.055 0.683,0.705l-0,5.092c-0,0.628 -0.056,0.682 -0.64,0.749l0,0.43l3.009,-0l0,-0.43c-0.584,-0.067 -0.639,-0.121 -0.639,-0.749"
            />
            <path
              id="path34"
              fill="#3482bd"
              d="M27.395,19.615c0.275,0 0.617,0.529 0.617,1.014c-0,0.187 -0.044,0.221 -0.232,0.232l-0.98,0.033c0.011,-0.915 0.352,-1.279 0.595,-1.279m0.771,3.449c-0.617,-0 -1.366,-0.594 -1.388,-1.586c1.058,-0.045 2.391,-0.155 2.556,-0.188c0.232,-0.045 0.276,-0.066 0.276,-0.44c-0,-1.036 -0.948,-1.808 -1.83,-1.808c-1.487,0 -2.677,1.257 -2.677,2.767c-0,1.377 0.958,2.434 2.512,2.434c0.375,0 1.279,-0.242 1.973,-1.244l-0.21,-0.354c-0.451,0.276 -0.793,0.419 -1.212,0.419"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
});

export default JoobleIcon;
