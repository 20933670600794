import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';

export default function EnhancedTableHead(props: {
  onSelectAllClick?: any;
  order: any;
  orderBy: string;
  numSelected?: number;
  rowCount: number;
  onRequestSort: (e: any, property: string) => void;
  headCells: Array<any>;
  sortableColumns: Array<string>;
  noEmptyCell?: boolean;
  icon?: React.ReactNode;
}) {
  const {
    sortableColumns,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    noEmptyCell
  } = props;

  const canSort = (property: string) => {
    return sortableColumns.includes(property);
  };

  const createSortHandler = (property: string) => (event: any) => {
    if (canSort(property)) onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {onSelectAllClick && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={(numSelected && numSelected > 0 && numSelected < rowCount) || false}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 'fit-content'
              }}
            >
              {headCell.icon}
              <Box>
                {canSort(headCell.id) ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </Box>
            </Box>
          </TableCell>
        ))}
        {!noEmptyCell && <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
}
