import { useState, useEffect, useContext, useCallback } from 'react';
import {
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Button,
  Stack,
  useTheme,
  FormControl,
  FormControlLabel,
  Switch
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import Close from '@mui/icons-material/Close';
import FilterList from '@mui/icons-material/FilterList';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SendIcon from '@mui/icons-material/Send';
import { SidebarContext } from 'contexts/SidebarContext';
import { SIDEBAR_FILTERS_POSITION, SIDEBAR_FILTERS_WIDTH } from 'config';
import {
  WorkerTableType,
  JobApplication,
  JobPosition,
  JobApplicationStatusKey
} from '@restworld/utility-types';
import { WorkerActionType } from '../../@types/actions';
import useComponentStatus from 'hooks/useComponentStatus';
import useGlobalOptionsFetchingContext from 'hooks/useGlobalOptionsFetchingContext';
import { useWorkerJobPositionsService } from '@restworld/data-services';
import JobApplicationDiscardedStatusBulkChange from 'components/JobApplicationDiscardedStatusBulkChange';
import { LoadingButton } from '@mui/lab';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import WorkerListingMenu from 'components/WorkerListingMenu';
import useDelayFunction from 'hooks/useDelayFunction';
import { WorkerFilterType } from '@restworld/data-services';

import { useAtom } from 'jotai';
import {
  semanticSearchAtom,
  semanticSearchSwitchAtom,
  maxWorkerRestaurantDistanceAtom,
  workerSearchAlgorithmAtom
} from 'contexts/WorkersIndex';

type ContextType = 'add_to_bench' | 'update_job_application_status' | 'remove_from_bench';

const contexts: ContextType[] = [
  'add_to_bench',
  'update_job_application_status',
  'remove_from_bench'
];

const componentStatusOptions = {
  [contexts[0]]: { showNotificationOnSuccess: true },
  [contexts[1]]: { showNotificationOnSuccess: true },
  [contexts[2]]: { showNotificationOnSuccess: true }
};

const discardedStatusKey = 'worker_jp_discarded';
const notSelectedStatus = 'worker_jp_not_selected';

type Props = {
  numSelected?: any;
  onSearchChange?: any;
  searchValue?: string;
  title: string;
  noFilter?: boolean;
  onCreateShortlist?: () => void;
  onRefresh?: () => void;
  type?: WorkerTableType;
  dispatch?: (action: WorkerActionType, payload: any) => void;
  jobPosition?: JobPosition;
  selectedWorkers?: Array<string>;
  setSelectedWorkers?: React.Dispatch<React.SetStateAction<Array<string>>>;
  reload?: () => void;
  semanticSearch?: boolean;
  filters?: WorkerFilterType;
  onFilterApplied?: (filters: WorkerFilterType) => void;
  onDisableSemanticSearch?: () => void;
  parentComponent?: string;
  showSwitch?: boolean;
  showSearchField?: boolean;
};

export default function EnhancedTableToolbar({
  numSelected,
  onSearchChange,
  searchValue,
  noFilter,
  onCreateShortlist,
  onRefresh,
  type,
  dispatch,
  jobPosition,
  selectedWorkers,
  reload,
  setSelectedWorkers,
  filters,
  onFilterApplied,
  onDisableSemanticSearch,
  parentComponent,
  showSwitch = false,
  showSearchField = true
}: Props) {
  const theme = useTheme();
  const { handleToggle } = useContext(SidebarContext);
  const [initialSearchValue, setInitialSearchValue] = useAtom(semanticSearchAtom);
  const { status, updateStatus } = useComponentStatus(contexts, componentStatusOptions);
  const { statusOptions } = useGlobalOptionsFetchingContext({ statusPrefix: ['worker_jp_'] });
  const workerJobPositionService = useWorkerJobPositionsService();

  const [workerSearchAlgorithm] = useAtom(workerSearchAlgorithmAtom);

  const { delayFunction } = useDelayFunction();

  const [showBulkApplicationDiscardedStatus, setShowBulkApplicationDiscardedStatus] =
    useState<boolean>(false);

  const [isWorkerListingMenuOpen, setIsWorkerListingMenuOpen] = useState<boolean>(false);
  const [workerListingMenuAnchorEl, setWorkerListingMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const [semanticSearchSwitch, setSemanticSearchSwitch] = useAtom(semanticSearchSwitchAtom);
  const [maxWorkerRestaurantDistance] = useAtom(maxWorkerRestaurantDistanceAtom);

  useEffect(() => {
    setInitialSearchValue('');
    setSemanticSearchSwitch(false);
  }, [type, setInitialSearchValue, setSemanticSearchSwitch]);

  useEffect(() => {
    setInitialSearchValue(searchValue || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenFilters = () => {
    handleToggle('filter', SIDEBAR_FILTERS_POSITION, SIDEBAR_FILTERS_WIDTH);
  };

  const handleCreateMultipleJobApplication = useCallback(() => {
    if (!jobPosition || selectedWorkers?.length === 0) return;

    const context: ContextType = 'add_to_bench';
    updateStatus(context, 'LOADING');

    delayFunction({
      actionName: 'aggiunta di più lavoratori al panchine',
      onCancel: () => {
        updateStatus(context, 'IDLE');
        setSelectedWorkers && setSelectedWorkers([]);
      },
      functionToExecute: () => {
        workerJobPositionService
          .createJobApplication({
            jobPositionId: jobPosition.id,
            workerId: selectedWorkers!,
            statusKey: 'worker_jp_proposed',
            source: 'passive',
            params: {
              found_through_algorithm: workerSearchAlgorithm
            }
          })
          .then(() => {
            updateStatus(context, 'SUCCESS', 'Worker aggiunto alla panchina con successo');
            !!dispatch
              ? dispatch('add_to_bench_multiple', { selectedWorkers })
              : reload && reload();
            setSelectedWorkers && setSelectedWorkers([]);
          })
          .catch((err) => updateStatus(context, 'ERROR', err?.data?.error));
      }
    });
  }, [
    workerJobPositionService,
    jobPosition,
    selectedWorkers,
    updateStatus,
    dispatch,
    reload,
    workerSearchAlgorithm,
    delayFunction,
    setSelectedWorkers
  ]);

  const handleUpdateMultipleJobApplication = useCallback(
    (status_key: string, contextStatus: ContextType, params?: Partial<JobApplication>) => {
      if (!jobPosition || selectedWorkers?.length === 0) return;
      const context: ContextType = contextStatus;
      updateStatus(context, 'LOADING');

      delayFunction({
        actionName: 'aggiornamento di più job applications',
        onCancel: () => {
          updateStatus(context, 'IDLE');
          setSelectedWorkers && setSelectedWorkers([]);
          setShowBulkApplicationDiscardedStatus(false);
        },
        functionToExecute: () => {
          workerJobPositionService
            .updateJobApplication({
              jobPositionId: jobPosition.id,
              workerId: selectedWorkers!,
              params: {
                status_key: status_key as JobApplicationStatusKey,
                ...params
              }
            })
            .then((res) => {
              if (res.status === 200) {
                if (status_key === discardedStatusKey) {
                  updateStatus(context, 'SUCCESS', 'Worker rimosso con successo');
                  !!dispatch
                    ? dispatch('remove_multiple', { selectedWorkers })
                    : !!reload && reload();
                } else {
                  updateStatus(context, 'SUCCESS', 'Stato aggiornato');
                  reload && reload();
                }
                setSelectedWorkers && setSelectedWorkers([]);
              }
            })
            .catch((err) => {
              updateStatus(context, 'ERROR', err?.data?.error);
            })
            .finally(() => setShowBulkApplicationDiscardedStatus(false));
        }
      });
    },
    [
      workerJobPositionService,
      jobPosition,
      selectedWorkers,
      updateStatus,
      dispatch,
      reload,
      delayFunction,
      setSelectedWorkers
    ]
  );

  const handleRemoveFromBench = (params?: Partial<JobApplication>) => {
    if (selectedWorkers?.length === 0 || !statusOptions) return;
    const discardedStatus = statusOptions?.worker_jp_?.find((s) => s.key === discardedStatusKey);
    if (!discardedStatus) return;

    handleUpdateMultipleJobApplication(discardedStatusKey, 'remove_from_bench', params);
  };

  const applySemanticSearch = useCallback(() => {
    onFilterApplied &&
      onFilterApplied({
        ...filters,
        semantic_search: initialSearchValue,
        max_restaurant_distance: maxWorkerRestaurantDistance
      });
  }, [filters, initialSearchValue, onFilterApplied, maxWorkerRestaurantDistance]);

  return (
    <>
      {!!jobPosition?.id && (
        <WorkerListingMenu
          isOpen={isWorkerListingMenuOpen}
          handleMenuClose={() => setIsWorkerListingMenuOpen(false)}
          listType={type}
          jobPositionId={jobPosition.id}
          anchorEl={workerListingMenuAnchorEl}
          reload={reload}
        />
      )}
      <JobApplicationDiscardedStatusBulkChange
        numOfWorkers={selectedWorkers?.length}
        onClose={() => {
          setShowBulkApplicationDiscardedStatus(false);
        }}
        onUpdateStatus={handleRemoveFromBench}
        isOpen={showBulkApplicationDiscardedStatus}
        contextStatus={status?.remove_from_bench.status}
      />
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Stack direction="row" spacing={3} alignItems="center">
          {numSelected > 0 && (
            <>
              <Stack direction="row" spacing={1}>
                <Typography variant="body1">{numSelected} selezionati</Typography>
                <Close
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      color: theme.palette.primary.main
                    }
                  }}
                  onClick={() => setSelectedWorkers && setSelectedWorkers([])}
                />
              </Stack>
              {type !== 'in_bench' && type !== 'in_shortlist' && (
                <LoadingButton
                  loading={status?.add_to_bench.status === 'LOADING'}
                  disabled={status?.add_to_bench.status === 'LOADING'}
                  onClick={handleCreateMultipleJobApplication}
                  loadingPosition={status?.add_to_bench.status === 'LOADING' ? 'start' : undefined}
                  variant="text"
                  startIcon={status?.add_to_bench.status === 'LOADING' && <ClearAllIcon />}
                >
                  Aggiungi alla panchina
                </LoadingButton>
              )}
              {type === 'in_bench' && (
                <>
                  <LoadingButton
                    loading={status?.remove_from_bench.status === 'LOADING'}
                    disabled={status?.remove_from_bench.status === 'LOADING'}
                    onClick={() => {
                      setShowBulkApplicationDiscardedStatus(true);
                    }}
                    loadingPosition={
                      status?.remove_from_bench.status === 'LOADING' ? 'start' : undefined
                    }
                    variant="text"
                    startIcon={status?.remove_from_bench.status === 'LOADING' && <ClearAllIcon />}
                  >
                    Rimuovi dalla panchina
                  </LoadingButton>
                  <LoadingButton
                    loading={status?.update_job_application_status.status === 'LOADING'}
                    disabled={status?.update_job_application_status.status === 'LOADING'}
                    onClick={(e) =>
                      handleUpdateMultipleJobApplication(
                        notSelectedStatus,
                        'update_job_application_status'
                      )
                    }
                    loadingPosition={
                      status?.update_job_application_status.status === 'LOADING'
                        ? 'start'
                        : undefined
                    }
                    variant="text"
                    startIcon={
                      status?.update_job_application_status.status === 'LOADING' && <ClearAllIcon />
                    }
                  >
                    Idoneo non selezionato
                  </LoadingButton>
                </>
              )}
            </>
          )}
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          {onSearchChange && showSearchField && (
            <TextField
              variant="outlined"
              placeholder="Cerca..."
              multiline={semanticSearchSwitch}
              minRows={3}
              maxRows={3}
              onChange={(e) => {
                setInitialSearchValue((e.target.value as string) || '');
                if (semanticSearchSwitch === false) {
                  onSearchChange(e);
                }
              }}
              value={initialSearchValue || ''}
              InputProps={{
                startAdornment: semanticSearchSwitch === false && (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: semanticSearchSwitch === true && (
                  <IconButton onClick={applySemanticSearch}>
                    <SendIcon color="primary" />
                  </IconButton>
                )
              }}
            />
          )}
          {showSwitch && (
            <Tooltip
              title="Abilita la ricerca semantica! Altrimenti continua con la ricerca testuale"
              disableHoverListener={type !== 'not_associated'}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={type !== 'not_associated'}
                      checked={semanticSearchSwitch || false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSemanticSearchSwitch(e.target.checked);
                        if (e.target.checked === false) {
                          onFilterApplied &&
                            onFilterApplied({
                              ...filters,
                              semantic_search: undefined,
                              search_vector: undefined
                            });
                          onDisableSemanticSearch && onDisableSemanticSearch();
                          setInitialSearchValue('');
                        }
                      }}
                      color="primary"
                    />
                  }
                  sx={{ justifyContent: 'flex-end', ml: 0 }}
                  label=""
                  labelPlacement="bottom"
                />
              </FormControl>
            </Tooltip>
          )}
          {onCreateShortlist && (
            <Button
              onClick={() => onCreateShortlist()}
              startIcon={<AddIcon />}
              sx={{ width: 150 }}
              variant="contained"
            >
              Crea Rosa
            </Button>
          )}
          {!noFilter && (
            <IconButton onClick={handleOpenFilters}>
              <FilterList />
            </IconButton>
          )}
          {onRefresh && (
            <Tooltip title="Ricarica la ricerca">
              <IconButton onClick={onRefresh}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
          {type === 'in_bench' && (
            <IconButton
              onClick={(e) => {
                setIsWorkerListingMenuOpen(true);
                setWorkerListingMenuAnchorEl(e.currentTarget);
              }}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        </Stack>
      </Toolbar>
    </>
  );
}
