import { useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  CircularProgress
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import useGlobalOptionsFetchingContext from 'hooks/useGlobalOptionsFetchingContext';
import { useWorkerJobPositionService } from 'service';

function DidCsContactCandidateFormDialog({
  isDialogVisible,
  id,
  jobPositionId,
  workerId,
  onCloseDialog
}: DidCsContactCandidateFormDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset
  } = useForm<IFormInput>({ mode: 'onChange' });
  const workerJobPositionService = useWorkerJobPositionService();
  const { options, isVariablesLoading } = useJobApplicationSubsequentContactOptions();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (workerId && jobPositionId) {
      setIsLoading(true);
      workerJobPositionService
        .updateJobApplication({
          workerId,
          jobPositionId,
          params: {
            applicant_contacted_through_after_klaaryo:
              data?.communicationChannel === NONE_SELECTED_KEY ? null : data.communicationChannel
          }
        })
        .then(() => {
          enqueueSnackbar('Risposta registrata con successo', { variant: 'success' });
        })
        .catch((e) => {
          console.error(e);
          enqueueSnackbar('Errore durante il salvataggio della risposta', { variant: 'error' });
        })
        .finally(() => {
          setIsLoading(false);
          onCloseDialog();
        });
    } else {
      onCloseDialog();
    }
  };

  const communicationOptionsWithIds = useMemo(
    () =>
      communicationOptions?.map((option) => ({
        ...option,
        id:
          options?.job_application_subsequent_contact_options?.find(
            (variable) => variable.key === option.key
          )?.id || NONE_SELECTED_KEY
      })),
    [options?.job_application_subsequent_contact_options]
  );

  useEffect(() => {
    reset();
  }, [jobPositionId, reset, workerId]);

  return (
    <Dialog id={id} open={isDialogVisible} sx={{ zIndex: 2005 }}>
      <DialogTitle
        sx={{
          textAlign: 'left',
          my: 3
        }}
      >
        Hai contattato il candidato prima di prendere una decisione
      </DialogTitle>
      <DialogContent sx={{ minWidth: 600, minHeight: 170, px: 3 }}>
        <form onSubmit={(e) => e.preventDefault()} action="">
          {isVariablesLoading ? (
            <Box sx={{ display: 'grid', placeItems: 'center' }}>
              <CircularProgress size={20} thickness={2} color="inherit" />
            </Box>
          ) : (
            <Box sx={{ mt: 1 }}>
              <Controller
                name="communicationChannel"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl>
                    <RadioGroup aria-labelledby="selezionare il canale di comunicazione" {...field}>
                      {communicationOptionsWithIds?.map(({ key, ...rest }) => (
                        <CustomFormControlLabel key={key} {...rest} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'end' }}>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
          disabled={!isValid}
          loading={isLoading}
        >
          Conferma
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default DidCsContactCandidateFormDialog;

interface IFormInput {
  communicationChannel?: string;
}
interface ICustomeFormControlLabelProps {
  id?: string;
  label: string;
  icon: JSX.Element;
}
interface DidCsContactCandidateFormDialogProps {
  id?: string;
  isDialogVisible: boolean;
  onCloseDialog: () => void;
  workerId: string;
  jobPositionId?: string;
}

const NONE_SELECTED_KEY = 'none';
const communicationOptions = [
  { key: 'none', label: "No, non l'ho fatto", icon: <PersonOffOutlinedIcon /> },
  { key: 'phone', label: 'Sì, al telefono', icon: <PhoneOutlinedIcon /> },
  { key: 'email', label: 'Sì, tramite e-mail', icon: <MailOutlineIcon /> },
  { key: 'whatsapp', label: 'Sì, su Whatsapp', icon: <WhatsAppIcon /> }
];

function CustomFormControlLabel({ label, icon, id }: ICustomeFormControlLabelProps) {
  return (
    <FormControlLabel
      value={id}
      control={<Radio />}
      label={
        <Stack direction="row" sx={{ alignItems: 'center', gap: 1 }}>
          {icon}
          {label}
        </Stack>
      }
    />
  );
}

function useJobApplicationSubsequentContactOptions() {
  const {
    variableOptions,
    isLoading: isVariablesLoading,
    fetchVariable
  } = useGlobalOptionsFetchingContext();

  useEffect(() => {
    fetchVariable(['job_application_subsequent_contact_options']);
  }, [fetchVariable]);

  return { options: variableOptions, isVariablesLoading };
}
