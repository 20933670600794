import { Badge, Stack, SvgIconTypeMap, Typography } from '@mui/material';
import { Variable, WorkerTransportationOption } from '@restworld/utility-types';
import DirectionsCar from '@mui/icons-material/DirectionsCar';
import CarRental from '@mui/icons-material/CarRental';
import DirectionsBus from '@mui/icons-material/DirectionsBus';
import DirectionsWalk from '@mui/icons-material/DirectionsWalk';
import Moped from '@mui/icons-material/Moped';
import DirectionsBike from '@mui/icons-material/DirectionsBike';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import LightTooltip from './LightTooltip';

interface Props {
  options?: WorkerTransportationOption[];
}
export default function TransportationOptions({ options }: Props) {
  if (!options || options.length === 0) return <span>-</span>;

  const optionsWithIcons: (Variable & {
    Icon: OverridableComponent<SvgIconTypeMap> & { muiName: string };
  })[] = options.map((v) => {
    let transportationIcon;
    if (v?.transportation_var.key === 'yes') transportationIcon = DirectionsCar;
    else if (v?.transportation_var.key === 'no') transportationIcon = DirectionsWalk;
    else if (v?.transportation_var.key === 'bycicle') transportationIcon = DirectionsBike;
    else if (v?.transportation_var.key === 'motorcycle') transportationIcon = Moped;
    else if (v?.transportation_var.key === 'public_transport') transportationIcon = DirectionsBus;
    else if (v?.transportation_var.key === 'sharing') transportationIcon = CarRental;
    else transportationIcon = DirectionsWalk;
    return {
      ...v.transportation_var,
      Icon: transportationIcon
    };
  });

  const DefaultIcon = optionsWithIcons[0].Icon;
  return (
    <LightTooltip
      title={
        <Stack direction="row" flexWrap="wrap" spacing={2} sx={{ p: 1, maxWidth: '320px' }}>
          {optionsWithIcons.map((v) => (
            <Stack key={v.id} alignItems="center" textAlign="center">
              <v.Icon />
              <Typography variant="caption" sx={{ fontSize: 11 }}>
                {v.label}
              </Typography>
            </Stack>
          ))}
        </Stack>
      }
    >
      <Badge
        badgeContent={optionsWithIcons.length}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiBadge-badge': {
            bgcolor: 'grey.200'
          }
        }}
      >
        <DefaultIcon color="action" />
      </Badge>
    </LightTooltip>
  );
}
