import { useState, useEffect, useCallback, lazy, Suspense, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Container, Box, Breadcrumbs, Link, CircularProgress, Stack, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ContactsIcon from '@mui/icons-material/Contacts';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

// styles
// import { Spacer } from '../../../styles/common';

// types & constants
import {
  JobPosition,
  Shortlist,
  WorkerTableType,
  Status,
  OrderAndPagination as OrderAndPaginationType
} from '@restworld/utility-types';
import { WorkerActionType } from '../../../@types/actions';

// components
import { TabPanel, a11yProps, Tab, Tabs } from '../../../components/Tab';
import Loader from '../../../components/Loader';
import { OrderAndPaginationDefaultValue } from 'constants/common';
import SidebarFiltersMainContainer from 'components/SidebarMainContainer';
import SidebarFilters from 'components/SidebarFilters';
import useSidebarFilters from 'hooks/useSidebarFilters';
import {
  useWorkerJobPositionsService,
  useWorkersService,
  WorkerListItem,
  WorkerFilterType
} from '@restworld/data-services';

import { useWorkerService } from 'service';

import { MAIN_PATHS } from 'routes/paths';
import useComponentStatus from 'hooks/useComponentStatus';
import BoxSuspenseFallback from 'components/BoxSuspenseFallback';
import useApiCache from 'hooks/useApiCache';
import useSubscriptionStatus from 'hooks/useSubscriptionStatus';
import SubscriptionStatus from 'components/SubscriptionStatus';

import List from './List';
// import EmployerTCStatus from 'components/EmployerTCStatus';
import { WorkersIndexAlgorithm, workerSearchAlgorithmAtom } from 'contexts/WorkersIndex';
import { useAtom } from 'jotai';
import { initialWorkerListingFilterData } from 'atoms/sidebar-filters.atoms';

import { AutoAwesomeOutlined } from '@mui/icons-material';
import SlotDiscrepanciesAlert from 'pages/showOrganization/slot-discrepancies-alert';
import Page from 'components/Page';

// ----------------------------------------------------------------------

type ContextType =
  | 'shortlists'
  | 'workers_not_associated'
  | 'workers_in_bench'
  | 'workers'
  | 'apiWorkers'
  | 'subscriptions'
  | 'payment_method';
const API_CACHE_URLS_TYPE = { workers: 'workers' };
const contexts: ContextType[] = [
  'shortlists',
  'workers_in_bench',
  'apiWorkers',
  'subscriptions',
  'payment_method',
  'workers_not_associated',
  'workers'
];

export default function WorkerListing({ jobPosition }: { jobPosition?: JobPosition }) {
  const workerService = useWorkersService();
  const tempWorkerService = useWorkerService();
  const { id: jobPositionId } = useParams();
  const { status, updateStatus } = useComponentStatus(contexts);
  const workerJobPositionService = useWorkerJobPositionsService();
  const { apiCacheUrls, updateApiCache } = useApiCache();

  const [workerList, setWorkerList] = useState<Array<WorkerListItem>>([]);

  const [, setWorkerSearchAlgorithm] = useAtom(workerSearchAlgorithmAtom);

  // Suscription logic
  const { activeSubscriptions, subscriptionMissingDays, hasPaymentMethod } =
    useSubscriptionStatus<ContextType>({
      subscriptionContext: 'subscriptions',
      paymentMethodContext: 'payment_method',
      updateStatus,
      // employerId: jobPosition?.restaurant?.employer_id
      organizationId: jobPosition?.restaurant?.organization_id
    });

  let defaultTab: 0 | 1 | 2 = 0;
  if (jobPosition?.status_key === 'jp_shortlist_formation') defaultTab = 1;
  else if (jobPosition?.status_key === 'jp_shortlist_sent') defaultTab = 2;

  const initialFilters: WorkerFilterType = useMemo(() => {
    return {
      ...initialWorkerListingFilterData,
      association_status:
        jobPosition?.status_key === 'jp_shortlist_formation' ? 'in_bench' : 'not_associated'
    };
  }, [jobPosition?.status_key]);

  const {
    activeTab,
    setActiveTab,
    filters,
    orderAndPagination,
    setOrderAndPaginationState,
    onFiltersApplied
  } = useSidebarFilters<WorkerFilterType>(initialFilters, defaultTab);

  const [dataCount, setDataCount] = useState<number>(0);

  const [shortlistList, setShortlistList] = useState<Shortlist[]>([]);
  const [shortlistHits, setShortlistHits] = useState<number>(0);

  const handleWorkerSearchAlgorithm = useCallback(
    (filters: WorkerFilterType) => {
      let algorithm: WorkersIndexAlgorithm;
      if (!!filters.granularity || !!filters.similarity_radius) {
        algorithm = 'collaborative_filter';
      } else if (!!filters.semantic_search) {
        algorithm = 'semantic_search';
      } else if (!!filters.direct_semantic_search && filters.direct_semantic_search === true) {
        algorithm = 'direct_semantic_search';
      } else if (!!filters.search_vector) {
        algorithm = 'text_search';
      } else if (
        !!filters.association_status ||
        !!filters.displacement_availability ||
        !!filters.domain_interest ||
        !!filters.experience ||
        !!filters.job_type ||
        !!filters.languages ||
        !!filters.occupations ||
        !!filters.sex ||
        !!filters.skills ||
        !!filters.transportation_options ||
        !!filters.research_status_key
      ) {
        algorithm = 'deterministic_filter';
      } else {
        algorithm = undefined;
      }

      setWorkerSearchAlgorithm(algorithm);
    },
    [setWorkerSearchAlgorithm]
  );

  const fetchWorkerList = useCallback(
    (
      useCache = true,
      backgroundCall = false,
      tab?: 0 | 1,
      contextStatus?: 'workers_in_bench' | 'workers_not_associated'
    ) => {
      if (!filters || activeTab === 2) return;
      const context = !!contextStatus ? contextStatus : 'workers';
      if (!backgroundCall) updateStatus(context, 'LOADING');

      let associationStatus: any = filters.association_status || 'not_associated';
      if (tab === 0) associationStatus = 'not_associated';
      else if (tab === 1) associationStatus = 'in_bench';

      const params: WorkerFilterType = {
        ...filters,
        association_status: associationStatus,
        search_vector: orderAndPagination.searchValue ?? undefined
      };
      let order_by;
      if (filters.job_application_status && associationStatus === 'in_bench') {
        params.job_application_status = 'worker_jp_interested';
      } else {
        params.job_application_status = undefined;
      }
      if (orderAndPagination.order && orderAndPagination.orderBy) {
        order_by = [orderAndPagination.order + ':' + orderAndPagination.orderBy];
      }

      if (filters.association_status !== 'in_bench') {
        params.is_contacted = undefined;
        params.job_application_status = undefined;
      }
      if (associationStatus !== 'not_associated') {
        params.research_status_key = undefined;
      }
      const limit = orderAndPagination.rowsPerPage;
      const offset = orderAndPagination.page * orderAndPagination.rowsPerPage;

      filters?.direct_semantic_search === true && filters?.association_status === 'not_associated'
        ? workerService
            .fetchSimilarWorkersUsingDirectSemanticSearch({
              jobPositionId,
              maxRestaurantDistance: filters.max_restaurant_distance
            })
            .then((res) => {
              if (backgroundCall && tab !== activeTab) return;

              setWorkerList(res.data);
            })
            .catch((err) => updateStatus(context, 'ERROR', err?.data?.error))
            .finally(() => {
              updateStatus(context, 'IDLE');
              updateStatus('apiWorkers', 'IDLE');
            })
        : // : workerService
          tempWorkerService
            .fetchWorkers({
              jobPositionId,
              limit,
              offset,
              order_by,
              filters: params,
              useCache: useCache
            })
            .then((res) => {
              if (backgroundCall && tab !== activeTab) return;
              // updateCacheUrls({
              //   [API_CACHE_URLS_TYPE.workers]: res.data.cacheUrl
              // });
              const resp = res.data.data.index_workers.data as WorkerListItem[];
              setWorkerList(resp.length ? resp : []);
              setDataCount(res.data.data.index_workers.hits);
            })
            .catch((err) => updateStatus(context, 'ERROR', err?.data?.error))
            .finally(() => {
              updateStatus(context, 'IDLE');
              updateStatus('apiWorkers', 'IDLE');
            });

      handleWorkerSearchAlgorithm(params);
    },
    [
      tempWorkerService,
      workerService,
      filters,
      orderAndPagination,
      jobPositionId,
      activeTab,
      updateStatus,
      handleWorkerSearchAlgorithm
    ]
  );

  const onTabChange = useCallback(
    (type?: WorkerTableType) => {
      setOrderAndPaginationState((orderAndPaginationState: OrderAndPaginationType) => ({
        ...OrderAndPaginationDefaultValue,
        rowsPerPage: orderAndPaginationState.rowsPerPage
      }));
      onFiltersApplied({
        ...filters,
        association_status: type,
        semantic_search: undefined
      });
    },
    [filters, onFiltersApplied, setOrderAndPaginationState]
  );

  useEffect(() => {
    fetchWorkerList(true);
  }, [fetchWorkerList]);

  const fetchShortlists = useCallback(() => {
    if (activeTab !== 2) return;
    const context: ContextType = 'shortlists';
    updateStatus(context, 'LOADING');
    const limit = orderAndPagination.rowsPerPage;
    const offset = orderAndPagination.page * orderAndPagination.rowsPerPage;
    const statusKeyFilter = filters.status_key || undefined;
    workerJobPositionService
      .fetchShortlists({ limit, offset, jobPositionId, statusKey: statusKeyFilter })
      .then((res) => {
        setShortlistList(res.data.shortlists);
        setShortlistHits(res.data.hits);
      })
      .catch((err) => {
        updateStatus(context, 'ERROR', err?.data?.error);
      })
      .finally(() => {
        updateStatus(context, 'IDLE');
      });
  }, [
    activeTab,
    jobPositionId,
    orderAndPagination,
    updateStatus,
    workerJobPositionService,
    filters
  ]);

  useEffect(() => {
    if (activeTab === 2) fetchShortlists();
  }, [activeTab, fetchShortlists]);

  useEffect(() => {
    if (status?.apiWorkers?.status === 'REFRESH') fetchWorkerList(false);
  }, [status?.apiWorkers?.status, fetchWorkerList]);

  const dispatch = useCallback(
    (action: WorkerActionType, payload: any) => {
      const apiCacheUrl = apiCacheUrls[API_CACHE_URLS_TYPE.workers];
      switch (action) {
        case 'remove_multiple':
        case 'add_to_bench_multiple':
          updateApiCache(apiCacheUrl, (response) => {
            const updatedWorkersList = response.data.data.filter(
              (wli: WorkerListItem) => !payload.selectedWorkers.includes(wli.worker.user_id)
            );
            const updatedHits = response.data.hits - payload.selectedWorkers.length;

            const updatedResponse = {
              ...response,
              data: {
                ...response.data,
                data: updatedWorkersList,
                hits: updatedHits
              }
            };
            return updatedResponse;
          });
          let backgroundCall =
            orderAndPagination.rowsPerPage - payload.selectedWorkers.length <= 5 ? false : true;

          fetchWorkerList(true);
          if (action === 'add_to_bench_multiple') {
            fetchWorkerList(false, backgroundCall, 0, 'workers_not_associated');
            fetchWorkerList(false, true, 1);
          } else if (action === 'remove_multiple') {
            fetchWorkerList(false, backgroundCall, 1, 'workers_in_bench');
            fetchWorkerList(false, true, 0);
          }

          break;

        case 'remove':
        case 'add_to_bench':
          updateApiCache(apiCacheUrl, (response) => {
            const updatedWorkersList = response.data.data.filter(
              (wli: WorkerListItem) => wli.worker.user_id !== payload.worker_id
            );
            const updatedHits = response.data.hits - 1;

            const updatedResponse = {
              ...response,
              data: {
                ...response.data,
                data: updatedWorkersList,
                hits: updatedHits
              }
            };
            return updatedResponse;
          });
          fetchWorkerList(true); // to fetch data from the cache and show on page directly
          fetchWorkerList(false, true, 0); // to fetch data in the background && show on the page when api is successful

          fetchWorkerList(false, true, 1);
          break;
        case 'update_research_status':
          !!payload.user_id &&
            !!payload.research_status &&
            setWorkerList((prev) =>
              prev.map((worker) => {
                if (worker.worker.user_id === payload.user_id)
                  return {
                    ...worker,
                    worker: {
                      ...worker.worker,
                      research_status: payload.research_status as Status,
                      research_status_last_updated: payload.research_status_last_updated
                    }
                  };
                else return worker;
              })
            );
          break;

        case 'worker_added_to_shortlist':
          fetchShortlists();
          break;
      }
    },
    [apiCacheUrls, updateApiCache, fetchWorkerList, orderAndPagination, fetchShortlists]
  );

  const reload = useCallback(() => updateStatus('apiWorkers', 'REFRESH'), [updateStatus]);

  const Filters = useCallback(
    ({ isOpen }: { isOpen?: boolean }) => {
      return (
        <Suspense fallback={<CircularProgress />}>
          <Filter
            onFilterApplied={(...params) => {
              onFiltersApplied(...params);
            }}
            initialFilters={filters}
            type={activeTab === 2 ? 'in_shortlist' : filters.association_status}
            isOpen={!!isOpen}
          />
        </Suspense>
      );
    },
    [activeTab, filters, onFiltersApplied]
  );
  const locationData = useLocation();
  const isWorkerPage = locationData.pathname === '/workers';
  return (
    <Page title="Workers">
      <Container maxWidth="xl">
        <SidebarFiltersMainContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                    <Typography variant="h3">
                      {isWorkerPage
                        ? 'Workers'
                        : jobPosition?.occupation?.label_it.label || jobPosition?.job_title}
                    </Typography>
                    {!!jobPosition && jobPosition.restaurant?.organization_id && (
                      <SubscriptionStatus
                        hasPaymentMethod={hasPaymentMethod}
                        activeSubscriptions={activeSubscriptions}
                        subscriptionMissingDays={subscriptionMissingDays}
                        type="jobPosition"
                        isSuccessFee={
                          (!!jobPosition.success_fee && jobPosition.success_fee > 0) || false
                        }
                        jobPositionInsertedAt={new Date(jobPosition.inserted_at)}
                        organizationId={jobPosition.restaurant?.organization_id}
                      />
                    )}
                    <AIHandledJobPositionIndicator jobPosition={jobPosition} />
                    {/* {!!jobPosition?.restaurant.employer?.user && (
                      <EmployerTCStatus user={jobPosition.restaurant.employer.user} />
                    )} */}
                  </Stack>
                  {!isWorkerPage && (
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                      <Link to={MAIN_PATHS.jobPositions} component={RouterLink}>
                        <Typography color="text.primary">Posizioni</Typography>
                      </Link>
                      <Link
                        to={`${MAIN_PATHS.restaurants}/${jobPosition?.restaurant_id}`}
                        component={RouterLink}
                      >
                        <Typography color="text.primary">
                          {jobPosition?.restaurant.restaurant_name}
                        </Typography>
                      </Link>
                      <Typography color="text.gray">Posizione</Typography>
                    </Breadcrumbs>
                  )}
                </Grid>
              </Grid>
              {/* <Spacer size="md" /> */}
              {!!jobPosition?.restaurant?.organization_id && (
                <SlotDiscrepanciesAlert organizationId={jobPosition.restaurant?.organization_id} />
              )}
              <Grid container>
                <Box sx={{ width: '100%' }}>
                  {!isWorkerPage && (
                    <Box>
                      <Tabs
                        value={activeTab}
                        onChange={(_e: any, newValue: number) => {
                          setActiveTab(newValue);
                        }}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          label="Workers"
                          icon={<ContactsIcon />}
                          onClick={() => onTabChange('not_associated')}
                          {...a11yProps(0, activeTab)}
                        />
                        <Tab
                          label="Panchina"
                          icon={<ClearAllIcon />}
                          onClick={() => onTabChange('in_bench')}
                          {...a11yProps(1, activeTab)}
                        />
                        <Tab
                          label="Rose"
                          icon={<EmojiEventsIcon />}
                          onClick={() => onTabChange('in_shortlist')}
                          {...a11yProps(2, activeTab)}
                        />
                      </Tabs>
                    </Box>
                  )}
                  <TabPanel
                    index={0}
                    value={[0, 1].includes(activeTab) ? 0 : 2}
                    style={{ position: 'relative' }}
                  >
                    {(status?.workers?.status === 'LOADING' ||
                      status?.workers_in_bench.status === 'LOADING' ||
                      status?.workers_not_associated.status === 'LOADING') && <Loader />}
                    <div
                      style={{
                        filter:
                          status?.workers?.status === 'LOADING' ||
                          status?.workers_in_bench.status === 'LOADING' ||
                          status?.workers_not_associated.status === 'LOADING'
                            ? 'blur(5px)'
                            : 'none'
                      }}
                    >
                      <Suspense fallback={<BoxSuspenseFallback />}>
                        <List
                          data={workerList}
                          orderAndPagination={{
                            ...orderAndPagination,
                            count: dataCount
                          }}
                          reload={reload}
                          dispatch={dispatch}
                          jobPosition={jobPosition}
                          setOrderAndPagination={setOrderAndPaginationState}
                          type={filters.association_status}
                          onTabChange={onTabChange}
                          setActiveTab={setActiveTab}
                          onRefresh={() => {
                            updateStatus('apiWorkers', 'REFRESH');
                          }}
                          filters={filters}
                          onFilterApplied={onFiltersApplied}
                        />
                      </Suspense>
                    </div>
                  </TabPanel>
                  <TabPanel index={2} value={activeTab} style={{ position: 'relative' }}>
                    {status?.shortlists.status === 'LOADING' && <Loader />}
                    <div
                      style={{
                        filter: status?.shortlists?.status === 'LOADING' ? 'blur(5px)' : 'none'
                      }}
                    >
                      <Suspense fallback={<BoxSuspenseFallback />}>
                        <ShortlistList
                          data={shortlistList}
                          orderAndPagination={{
                            ...orderAndPagination,
                            count: shortlistHits
                          }}
                          reload={() => {
                            fetchShortlists();
                            reload();
                          }}
                          jobPosition={jobPosition}
                          setOrderAndPagination={setOrderAndPaginationState}
                          statusKey={filters.status_key}
                          dispatch={dispatch}
                        />
                      </Suspense>
                    </div>
                  </TabPanel>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </SidebarFiltersMainContainer>
      </Container>
      <SidebarFilters>
        <Filters />
      </SidebarFilters>
    </Page>
  );
}

// LAZY
const ShortlistList = lazy(() => import('./ShortlistList'));
const Filter = lazy(() => import('./Filter'));

export const AIHandledJobPositionIndicator = ({ jobPosition }: { jobPosition?: JobPosition }) => {
  return (
    <>
      {!!jobPosition && jobPosition?.manage_using_klaaryo && !!jobPosition?.klaaryo_campaign_id && (
        <Tooltip title="La posizione lavorativa è gestita tramite l'agente AI">
          <AutoAwesomeOutlined color="primary" />
        </Tooltip>
      )}
    </>
  );
};
