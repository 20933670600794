import { useState, useEffect, useCallback } from 'react';
import { useRwMembersService } from '@restworld/data-services';
import { Employer, RWMember, RWMemberRoleKeys } from '@restworld/utility-types';
import useComponentStatus from 'hooks/useComponentStatus';
import { Avatar, Typography, Stack, Autocomplete, Box, TextField } from '@mui/material';

type ContextType = 'rw_members';
const contexts: ContextType[] = ['rw_members'];

type Props = {
  error: boolean;
  isEditMode: boolean;
  assigned_to?: string;
  updateEmployerAssignation?: (key: keyof Employer, value: any) => void;
  updateEntity?: (id: any) => void;
  label?: string;
  rolesToLoad?: string[];
};

export default function CsmAssignation({
  assigned_to,
  isEditMode,
  updateEmployerAssignation,
  error,
  updateEntity,
  label,
  rolesToLoad = ['csm']
}: Props) {
  const { updateStatus, status } = useComponentStatus(contexts);
  const [selectedCsm, setSelectedCsm] = useState<RWMember & { value: string; label: string }>();
  const [currentCsmId, setCurrentCsmId] = useState<string | undefined>(assigned_to);
  const [csmList, setCsmList] = useState<(RWMember & { value: string; label: string })[]>();

  const rwMemberService = useRwMembersService();

  useEffect(() => {
    const context: ContextType = 'rw_members';
    updateStatus(context, 'LOADING');
    rwMemberService
      .fetchRWMembers({
        limit: 100,
        offset: 0,
        roles: rolesToLoad as RWMemberRoleKeys[]
      })
      .then((res) => {
        if (res.status === 200) {
          updateStatus(context, 'SUCCESS');
          setCsmList(
            res.data.data
              .map((rw: RWMember) => ({
                ...rw,
                value: rw.user_id,
                label: rw.name + ' ' + rw.surname
              }))
              .sort((a, b) => (a?.roles?.map((r) => r.key)?.includes('csm') ? -1 : 1))
          );
        }
      })
      .catch((err) => {
        updateStatus(context, 'ERROR', err?.data?.error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rwMemberService]);

  const updateSelectedCsm = useCallback(
    () => setSelectedCsm(csmList?.find(({ user_id }: RWMember) => user_id === currentCsmId)),
    [csmList, currentCsmId]
  );

  useEffect(() => {
    updateSelectedCsm();
  }, [updateSelectedCsm]);

  useEffect(() => {
    setCurrentCsmId(assigned_to);
  }, [assigned_to, isEditMode]);

  return (
    <>
      {!isEditMode ? (
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Avatar src={selectedCsm?.icon_url} alt={`${selectedCsm?.label}`} sx={{ mr: 2 }} />
          <Typography variant="body2">{selectedCsm?.label}</Typography>
        </Stack>
      ) : (
        <Autocomplete
          loading={status?.rw_members?.status === 'LOADING'}
          fullWidth
          value={selectedCsm || null}
          groupBy={(option) =>
            option?.roles?.map((r) => r.key)?.includes('sales')
              ? 'Sales'
              : option?.roles?.map((r) => r.key)?.includes('csm')
              ? 'Customer Success'
              : ''
          }
          onChange={(event, newValue) => {
            setSelectedCsm(newValue || undefined);
            updateEmployerAssignation &&
              updateEmployerAssignation('assigned_to', newValue?.user_id);

            updateEntity && updateEntity(newValue?.user_id);
          }}
          options={csmList || []}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              label={label || 'Assegnato a'}
              helperText={error ? 'Conferma che il campo non è vuoto' : ''}
            />
          )}
          isOptionEqualToValue={(option, value) => option?.value === value?.value || false}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Avatar
                src={option.icon_url}
                alt={`${option.name} ${option.surname}`}
                sx={{ mr: 2 }}
              />
              {option.label}
            </Box>
          )}
          autoHighlight
        />
      )}
    </>
  );
}
