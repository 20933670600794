import { Skeleton, Grid, Stack } from '@mui/material';
import React from 'react';

export default function BoxSuspenseFallback() {
  return (
    <Grid container spacing={3} alignItems="center">
      {Array.from(Array(10).keys()).map((index) => (
        <React.Fragment key={index}>
          <Grid item xs={2}>
            <Skeleton variant="circular" width={80} height={80} />
          </Grid>
          <Grid item xs={5}>
            <Skeleton variant="rectangular" height={80} />
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Stack>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
