import { Box, BoxProps } from '@mui/material';
import { forwardRef } from 'react';

const JobRapidoIcon = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return (
    <Box {...props} ref={ref}>
      <svg
        viewBox="0 0 30 30"
        width="30"
        height="30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="#000">
          <path d="M11.042,0l1.662,0l0,2.41l-1.662,-0l0,-2.41Zm1.517,6.627l-0,17.244c-0,5.506 -3.574,6.129 -5.256,6.129c-0.499,0 -0.831,-0.062 -0.831,-0.062l-0,-1.288c-0,-0 0.29,0.062 0.727,0.062c1.329,-0 3.968,-0.54 3.968,-4.799l-0,-17.265l1.392,0l-0,-0.021Z" />
          <path d="M15.156,6.524l2.223,-0l-0,2.95c-0,0.727 -0.063,1.329 -0.063,1.329l0.063,0c0.789,-2.514 2.638,-4.446 5.256,-4.446c0.457,0 0.893,0.104 0.893,0.104l0,2.223c0,0 -0.394,-0.062 -0.831,-0.062c-2.347,-0 -4.092,1.849 -4.82,4.3c-0.332,1.06 -0.457,2.182 -0.457,3.241l0,7.023l-2.264,-0l-0,-16.662Z" />
        </g>
      </svg>
    </Box>
  );
});

export default JobRapidoIcon;
