import { differenceInDays, fromUnixTime } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOrganizationsService } from '@restworld/data-services';
import { StripeSubscription } from '@restworld/utility-types';
import { AsyncStatus } from '../@types/componentLifecycle';
import { IndexStripePaymentMethods } from 'service/organizations';
import { useOrganizationService } from 'service';

interface Props<ContextType> {
  subscriptionContext: ContextType;
  paymentMethodContext: ContextType;
  updateStatus: (context: string, newStatus: AsyncStatus, message?: string | undefined) => void;
  // employerId?: EntityId;
  organizationId?: string;
  companyId?: string;
}

export default function useSubscriptionStatus<ContextType extends string>({
  subscriptionContext,
  paymentMethodContext,
  updateStatus,
  // employerId
  organizationId,
  companyId
}: Props<ContextType>) {
  const organizationService = useOrganizationsService();
  const tempOrganizationService = useOrganizationService();
  const [activeSubscriptions, setActiveSubscriptions] = useState<StripeSubscription[] | null>(null);
  // const activeSubscription = useMemo(
  //   () => activeSubscriptions?.find((sub) => sub.status === 'active'),
  //   [activeSubscriptions]
  // );
  const hasSubscriptionActive = useMemo(
    () =>
      !activeSubscriptions
        ? null
        : activeSubscriptions.length > 0 &&
          !!activeSubscriptions.find((s) => s.status === 'active'),
    [activeSubscriptions]
  );
  const subscriptionMissingDays = useMemo(() => {
    if (!activeSubscriptions || activeSubscriptions.length === 0) return null;
    return activeSubscriptions.map((sub) => {
      const deadline = fromUnixTime(sub.current_period_end);
      return differenceInDays(deadline, new Date());
    });
  }, [activeSubscriptions]);

  const [companyPaymentMethods, setCompanyPaymentMethods] = useState<IndexStripePaymentMethods>([]);

  const hasPaymentMethod = useMemo(() => {
    if (!companyPaymentMethods) return null;

    return companyPaymentMethods.some((company) => {
      return company?.payment_methods?.length > 0;
    });
  }, [companyPaymentMethods]);

  const indexSubscriptions = useCallback(() => {
    if ((!organizationId && !companyId) || !subscriptionContext) return;
    updateStatus(subscriptionContext, 'LOADING');

    organizationService
      .fetchStripeSubscriptions({ organizationId, companyId })
      .then((res) => {
        setActiveSubscriptions(
          res.data.sort(
            (a: StripeSubscription, b: StripeSubscription) =>
              a.current_period_end - b.current_period_end
          )
        );
        updateStatus(subscriptionContext, 'SUCCESS');
      })
      .catch((err) => updateStatus(subscriptionContext, 'ERROR', err?.data?.error));
  }, [updateStatus, subscriptionContext, organizationId, companyId, organizationService]);

  const indexPaymentMethods = useCallback(() => {
    if ((!organizationId && !companyId) || !paymentMethodContext) return;
    updateStatus(paymentMethodContext, 'LOADING');

    // organizationService
    tempOrganizationService
      .fetchStripePaymentMethods({ organizationId, companyId })
      .then((res) => setCompanyPaymentMethods(res.data))
      .finally(() => updateStatus(paymentMethodContext, 'SUCCESS'))
      .catch((err) => updateStatus(paymentMethodContext, 'ERROR', err?.data?.error));
  }, [
    updateStatus,
    paymentMethodContext,
    organizationId,
    companyId,
    // organizationService,
    tempOrganizationService
  ]);

  useEffect(() => {
    indexSubscriptions();
  }, [indexSubscriptions]);

  useEffect(() => {
    indexPaymentMethods();
  }, [indexPaymentMethods]);

  return {
    activeSubscriptions,
    hasSubscriptionActive,
    subscriptionMissingDays,
    hasPaymentMethod,
    indexSubscriptions,
    companyPaymentMethods
  };
}
