import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
// import fileFill from '@iconify/icons-eva/file-fill';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Onboarding',
    path: '/registration-forms',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  },
  // {
  //   title: 'Lead',
  //   path: '/',
  //   icon: <Icon icon={homeFill} {...ICON_SIZE} />
  // },
  // {
  //   title: 'Employer',
  //   path: '/employers',
  //   icon: <Icon icon={homeFill} {...ICON_SIZE} />
  // },
  {
    title: 'Employers',
    path: '/organizations',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  },
  {
    title: 'Locali',
    path: '/restaurants',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  },
  {
    title: 'Posizioni',
    path: '/job-positions',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  },
  {
    title: 'Storico',
    path: '/history',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  },
  {
    title: 'Workers',
    path: '/workers',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  }
  // {
  //   title: 'Worker',
  //   path: '/',
  //   icon: <Icon icon={homeFill} {...ICON_SIZE} />
  // },
  // {
  //   title: 'Rose',
  //   path: '/',
  //   icon: <Icon icon={homeFill} {...ICON_SIZE} />
  // }
  // {
  //   title: 'Home',
  //   path: '/',
  //   icon: <Icon icon={homeFill} {...ICON_SIZE} />
  // },
  // { title: 'Dashboard', path: PATH_DASHBOARD.root, icon: <Icon icon={fileFill} {...ICON_SIZE} /> }
];

export default menuConfig;
