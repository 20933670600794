import { JobApplication as BasicJobApplication } from '@restworld/utility-types';
import { useState, useMemo, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slide,
  TextField,
  Box,
  Typography,
  Stack,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Alert
} from '@mui/material';
import ChipOptions from './ChipOptions';
import Dialog from './Dialog';
import useGlobalOptionsFetchingContext from 'hooks/useGlobalOptionsFetchingContext';
import { Done as DoneIcon, InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AsyncStatus } from './../@types/componentLifecycle';
import { FormControlLabel } from '@mui/material';
import { Switch } from '@mui/material';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';

type JobApplication = BasicJobApplication & { invalid?: boolean };

export default function JobApplicationDiscardedStatusBulkChange({
  numOfWorkers,
  onUpdateStatus,
  isOpen,
  contextStatus,
  onClose
}: {
  numOfWorkers?: number;
  onUpdateStatus: (params?: Partial<JobApplication>) => void;
  isOpen: boolean;
  onClose: VoidFunction;
  contextStatus?: AsyncStatus;
}) {
  const [jobApplication, setJobApplication] = useState<Partial<JobApplication>>({});

  const { variableOptions, isLoading, statusOptions } = useGlobalOptionsFetchingContext({
    variableNames: ['worker_discarded_reason'],
    statusPrefix: ['worker_jp_']
  });
  const [isDiscardedOtherSelected, setIsDiscardedOtherSelected] = useState<boolean>(false);

  const discardedStatus = statusOptions?.worker_jp_?.find((s) => s.key === 'worker_jp_discarded');

  useEffect(() => {
    if (!isOpen) setJobApplication({});
  }, [isOpen]);

  const handleUpdateJobApplication = (key: keyof JobApplication, value: any) => {
    setJobApplication((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const content = useMemo(() => {
    return (
      <FormControl fullWidth>
        <InputLabel id="discarded_by_label">Da chi vengono scartati?</InputLabel>
        <Select
          labelId="discarded_by_label"
          id="discarded_by_select"
          label="Da chi vengono scartati?"
          value={jobApplication.discarded_by || ''}
          onChange={(e) => handleUpdateJobApplication('discarded_by', e.target.value)}
          MenuProps={{ sx: { zIndex: 2006 } }}
        >
          <MenuItem value="csm">CSM</MenuItem>
          <MenuItem value="employer">Employer</MenuItem>
          <MenuItem value="worker">Worker</MenuItem>
        </Select>

        {!isLoading && !!jobApplication.discarded_by && (
          <Box mt={2}>
            <Typography mb={1}>Come mai?</Typography>
            <ChipOptions
              options={
                variableOptions?.worker_discarded_reason?.filter(
                  (option) => option.parent_key === jobApplication.discarded_by
                ) || []
              }
              selectedOption={
                isDiscardedOtherSelected
                  ? { id: 'other', key: 'other', label: 'Altro' }
                  : jobApplication.discarded_reason_var
              }
              onClick={(option) => {
                if (option.id === 'other') {
                  setIsDiscardedOtherSelected(true);
                  handleUpdateJobApplication('discarded_reason_var', undefined);
                  handleUpdateJobApplication('discarded_reason', undefined);
                } else {
                  handleUpdateJobApplication('discarded_reason_var', option);
                  handleUpdateJobApplication('discarded_reason', option.id);
                  setIsDiscardedOtherSelected(false);
                }
              }}
              showOther
            />
            <Slide in={isDiscardedOtherSelected} direction="up" exit={false} unmountOnExit>
              <TextField
                label="Scrivi la motivazione"
                value={jobApplication.discarded_other_reason || ''}
                onChange={(e) =>
                  handleUpdateJobApplication('discarded_other_reason', e.target.value)
                }
                fullWidth
                sx={{ mt: 2 }}
              />
            </Slide>
            <Box sx={{ mt: 4 }}>
              <FormControlLabel
                sx={{
                  justifyContent: 'space-between',
                  width: '98%',
                  ml: 1,
                  alignItems: 'center'
                }}
                control={
                  <Switch
                    id="is_invalid"
                    checked={(jobApplication.invalid || false) as boolean}
                    onChange={(e) => handleUpdateJobApplication('invalid', e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <Stack direction="row" spacing={1} alignItems={'center'}>
                    <Typography variant="body1">Le candidature sono invalide?</Typography>
                    <Tooltip title="Ai fini della challenge e dei referral, non verranno conteggiate">
                      <IconButton size="small">
                        <InfoOutlined fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                }
                labelPlacement="start"
              />
            </Box>
          </Box>
        )}
      </FormControl>
    );
  }, [jobApplication, variableOptions, isLoading, isDiscardedOtherSelected]);

  return (
    <Dialog open={isOpen} PaperProps={{ sx: { minWidth: '30%' } }} onClose={onClose}>
      <DialogTitle>
        <Stack direction="column">
          <Stack direction="row" spacing={2} alignItems="center">
            <span>{numOfWorkers} worker saranno</span>
            <span>{discardedStatus?.label}</span>
          </Stack>
          <Alert severity="warning" sx={{ mt: 1 }}>
            Questa azione rimuoverà dalla panchina {numOfWorkers} worker
          </Alert>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 3 }}>{content}</Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <LoadingButton
            onClick={() => onUpdateStatus(jobApplication)}
            variant="contained"
            color="primary"
            disabled={!jobApplication.discarded_reason && !jobApplication.discarded_other_reason}
            loading={contextStatus === 'LOADING'}
          >
            {contextStatus === 'SUCCESS' ? <DoneIcon /> : 'Conferma'}
          </LoadingButton>
          <Button
            onClick={onClose}
            variant="contained"
            color="inherit"
            disabled={contextStatus === 'LOADING'}
          >
            Annulla
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
