import { Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { KlaaryoConversation } from 'service/worker';
import { DetailsItem } from './RestaurantDetailView';
import {
  klaaryoConversationStatusMenuItems,
  PrescreeningQuestionAnswersView,
  RedirectToKlaaryoConversation
} from './KlaaryoConversationModal';

type Props = {
  onClose: () => void;
  conversationId?: number;
  klaaryoConversation?: KlaaryoConversation;
};

export default function KlaaryoConversationView({
  klaaryoConversation,
  onClose,
  conversationId
}: Props) {
  return (
    <>
      <DialogContent sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Stato</Typography>
            <DetailsItem>
              {
                klaaryoConversationStatusMenuItems?.find(
                  (item) => item.value === klaaryoConversation?.conversation_status
                )?.label
              }
            </DetailsItem>
          </Grid>
          <Grid item xs={6} container justifyContent="end">
            <RedirectToKlaaryoConversation conversationId={conversationId} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Summary</Typography>
            <DetailsItem>{klaaryoConversation?.summary}</DetailsItem>
          </Grid>
          <Grid item xs={12}>
            <PrescreeningQuestionAnswersView
              conversationSummaryObject={klaaryoConversation?.conversation_summary_object}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="inherit">
          Chiudi
        </Button>
      </DialogActions>
    </>
  );
}
