import Box from '@mui/material/Box';
import { Tab as StyledTab, Tabs as StyledTabs, TabPanel as StyledTabPanel } from '../styles/common';
import { useTheme } from '@mui/material/styles';

export function a11yProps(index: number, value?: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    active: index === value ? 'true' : undefined
  };
}

export function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </StyledTabPanel>
  );
}

export const Tab = (props: any) => {
  const theme = useTheme();
  return <StyledTab {...props} theme={theme} />;
};

export const Tabs = (props: any) => {
  const theme = useTheme();
  return <StyledTabs {...props} theme={theme} />;
};
