import { Box, BoxProps } from '@mui/material';
import { forwardRef } from 'react';

const BakecaIcon = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return (
    <Box {...props} ref={ref}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="1.23 305.217 35.8 35.8"
      >
        <g fill-rule="evenodd" clip-rule="evenodd">
          <path fill="#FFEC00" d="M1.23 305.217h35.798v35.797H1.23z"></path>
          <path
            fill="#1D1D1B"
            d="M7.494 335.538h16.571c4.599 0 7.665-3.029 7.665-7.081 0-3.212-1.678-5.292-5.365-6.606 2.592-1.132 3.905-2.848 3.905-5.184 0-2.883-2.007-6.022-8.212-6.022H7.494v24.893zm8.724-3.797v-6.971h2.847c2.848 0 4.235 1.277 4.235 3.358 0 2.445-1.533 3.612-4.454 3.612h-2.628zm0-10.767v-6.533h2.519c2.847 0 3.942 1.35 3.942 3.249 0 1.789-1.095 3.285-3.942 3.285h-2.519z"
          ></path>
        </g>
      </svg>
    </Box>
  );
});

export default BakecaIcon;
