import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { EntityId, WorkerTableType } from '@restworld/utility-types';
import { useCallback, useState } from 'react';
import { CleaningServices as CleaningServicesIcon, Download, Mail } from '@mui/icons-material';
import CleanPendingApplications from './CleanPendingApplications';
import Loader from './Loader';
import { useWorkerJobPositionsService } from '@restworld/data-services';
import { useWorkerJobPositionService } from 'service';
import useComponentStatus from 'hooks/useComponentStatus';
import ActionConfirmationModal from './ActionConfirmationModal';

type Props = {
  handleMenuClose: () => void;
  anchorEl?: Element | null;
  isOpen: boolean;
  jobPositionId: EntityId;
  reload?: () => void;
  listType?: WorkerTableType;
};

type ContextType = 'updated_job_proposal_emails';
const contexts: ContextType[] = ['updated_job_proposal_emails'];
const componentStatusOptions = {
  [contexts[0]]: { showNotificationOnSuccess: true }
};

export default function WorkerListingMenu({
  handleMenuClose,
  anchorEl,
  isOpen,
  jobPositionId,
  reload,
  listType
}: Props) {
  const [isCleanPendingApplicationsDialogOpen, setIsCleanPendingApplicationsDialogOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { status, updateStatus } = useComponentStatus(contexts, componentStatusOptions);
  const [isEmailConfirmationModalOpen, setIsEmailConfirmationModalOpen] = useState<boolean>(false);

  const workerJobPositionService = useWorkerJobPositionsService();
  const tempWorkerJobPositionService = useWorkerJobPositionService();

  const handleDownloadInterestedWorkers = useCallback(() => {
    setIsLoading(true);
    workerJobPositionService
      .getInterestedWorkersCsv(jobPositionId)
      .then((response) => {
        setIsLoading(false);
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `interested-workers-${jobPositionId}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [jobPositionId, workerJobPositionService]);

  const openEmailConfirmationModal = useCallback(() => {
    setIsEmailConfirmationModalOpen(true);
  }, []);

  const sendUpdatedJobProposalEmails = useCallback(() => {
    const context: ContextType = 'updated_job_proposal_emails';
    updateStatus(context, 'LOADING');
    tempWorkerJobPositionService
      .sendUpdatedJobProposalEmails(jobPositionId)
      .then(() => {
        updateStatus(context, 'SUCCESS', 'Emails sent successfully');
        setIsEmailConfirmationModalOpen(false);
      })
      .catch((error) => {
        updateStatus(context, 'ERROR', 'Error sending emails');
        console.error({ error });
      });
  }, [tempWorkerJobPositionService, jobPositionId, updateStatus]);

  return (
    <>
      <ActionConfirmationModal
        isOpen={isEmailConfirmationModalOpen}
        actionText="Stai per inviare ai lavoratori proposti e non interessati un'e-mail di notifica sull'aggiornamento della posizione lavorativa. Confermi?"
        onClose={() => setIsEmailConfirmationModalOpen(false)}
        action={() => {
          sendUpdatedJobProposalEmails();
        }}
        isLoading={status?.updated_job_proposal_emails?.status === 'LOADING'}
      />
      <CleanPendingApplications
        isOpen={isCleanPendingApplicationsDialogOpen}
        handleClose={() => setIsCleanPendingApplicationsDialogOpen(false)}
        jobPositionId={jobPositionId}
        refresh={reload}
        onSuccess={() => handleMenuClose()}
      />
      <Menu onClose={handleMenuClose} anchorEl={anchorEl} open={isOpen} sx={{ zIndex: 1299 }}>
        <MenuItem onClick={() => setIsCleanPendingApplicationsDialogOpen(true)}>
          <ListItemIcon>
            <CleaningServicesIcon />
          </ListItemIcon>
          <ListItemText>Pulisci la panchina</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDownloadInterestedWorkers}>
          <ListItemIcon>{isLoading ? <Loader /> : <Download />}</ListItemIcon>
          <ListItemText>Scarica interessati</ListItemText>
        </MenuItem>

        <MenuItem onClick={openEmailConfirmationModal}>
          <ListItemIcon>
            <Mail />
          </ListItemIcon>
          <ListItemText>Invia e-mail di aggiornamento posizione</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
