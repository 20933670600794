import { GradingOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { JobApplicationUpdated, KlaaryoConversation } from 'service/worker';
import Label, { LabelColor } from './Label';

const getKlaaryoConversationLabel = (conversation: KlaaryoConversation) => {
  let val = {
    label: '',
    color: 'default'
  };
  switch (conversation?.conversation_status) {
    case 'to_contact':
      val = {
        label: 'Da contattare',
        color: 'secondary'
      };
      break;
    case 'contacted':
      val = {
        label: 'Contattato',
        color: 'info'
      };
      break;
    case 'replied':
      val = {
        label: 'Ha risposto',
        color: 'info'
      };
      break;
    case 'not_interested':
      val = {
        label: 'Non interessato',
        color: 'warning'
      };
      break;
    case 'human_action_required':
      val = {
        label: 'Supporto necessario',
        color: 'error'
      };
      break;
    case 'unresponsive':
      val = {
        label: 'Non risponde',
        color: 'info'
      };
      break;
    case 'conversation_closed':
      val = {
        label: 'Chiuso',
        color: 'success'
      };
      break;
    default:
      val = {
        label: 'In corso',
        color: 'default'
      };
  }
  return (
    <Label color={val.color as LabelColor} variant="ghost" sx={{ cursor: 'pointer' }}>
      {val.label}
    </Label>
  );
};

type Props = {
  jobApplication?: JobApplicationUpdated;
  onClick?: () => void;
  showLabel?: boolean;
};

export default function KlaaryoConversationIcon({
  jobApplication,
  onClick,
  showLabel = false
}: Props) {
  return (
    <>
      {!!jobApplication?.klaaryo_conversation && showLabel ? (
        <div onClick={onClick}>
          {getKlaaryoConversationLabel(jobApplication?.klaaryo_conversation)}
        </div>
      ) : (
        <Tooltip
          title="Conversazione con agente AI non disponibile"
          disableHoverListener={!!jobApplication?.klaaryo_conversation}
        >
          <div>
            <IconButton disabled={!jobApplication?.klaaryo_conversation} onClick={onClick}>
              <GradingOutlined />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </>
  );
}
