import { useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useWorkerJobPositionsService, useWorkersService } from '@restworld/data-services';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  BoxProps,
  Button,
  Stack,
  FormControlLabel,
  Switch,
  IconButton,
  CircularProgress,
  useTheme,
  Tooltip,
  DialogContent,
  Typography,
  Divider,
  Link,
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  DialogActions
} from '@mui/material';
import { Status, UpdateStatus } from '../@types/componentLifecycle';
import useComponentStatus from 'hooks/useComponentStatus';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import { Check, Close, Visibility as VisibilityIcon, ZoomIn, ZoomOut } from '@mui/icons-material';
import { EntityId } from '@restworld/utility-types';

import { WorkerActionType } from '../@types/actions';
import Delete from '@mui/icons-material/Delete';
import Error from '@mui/icons-material/Error';
import Iconify from './Iconify';
import Dialog from './Dialog';

type ContextType = 'cv_upload' | 'cv_delete' | 'cv_show' | 'create_job_application';
const contexts: ContextType[] = ['cv_upload', 'cv_delete', 'cv_show', 'create_job_application'];
const componentStatusOptions = {
  cv_upload: { showNotificationOnSuccess: true },
  cv_delete: { showNotificationOnSuccess: true },
  cv_show: { showNotificationOnSuccess: true },
  create_job_application: { showNotificationOnSuccess: true }
};

interface Props extends BoxProps {
  workerId: EntityId;
  cv?: string;
  showCV: boolean;
  minimalView?: boolean;
  radioView?: boolean;
  uploadable?: boolean;
  customStatus?: Status;
  customUpdateStatus?: UpdateStatus;
  customUploadContext?: string;
  customDeleteContext?: string;
  jobPositionId?: EntityId;
  reload?: () => void;
  dispatch?: (action: WorkerActionType, payload: any) => void;
}

export default function WorkerCV({
  workerId,
  cv,
  showCV,
  minimalView = false,
  radioView = false,
  uploadable = true,
  customStatus,
  customUpdateStatus,
  customUploadContext,
  customDeleteContext,
  jobPositionId,
  reload,
  dispatch: externalDispatch,
  ...other
}: Props) {
  const theme = useTheme();

  const workerService = useWorkersService();
  const workerJobPositionService = useWorkerJobPositionsService();
  const uplaodInputRef = useRef<HTMLInputElement>(null);
  const { status: defaultStatus, updateStatus: defaultUpdateStatus } = useComponentStatus(
    contexts,
    componentStatusOptions
  );
  const [internalCv, setInternalCv] = useState<string | undefined>(cv);
  const [internalShowCV, setInternalShowCV] = useState<boolean>(showCV);

  const [isCVOpened, setIsCVOpened] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [cvScale, setCvScale] = useState<number>(1);

  const [hasCVLoaded, setHasCVLoaded] = useState<boolean>(false);

  useEffect(() => setInternalCv(cv), [cv]);

  useEffect(() => {
    isCVOpened && setHasCVLoaded(true);
  }, [isCVOpened]);

  const dispatch = useCallback((action: WorkerActionType, payload: any) => {
    switch (action) {
      case 'worker_upload_cv':
        setInternalCv(payload.cv);
        break;
      case 'worker_delete_cv':
        setInternalCv(undefined);
        break;
      case 'worker_update':
        setInternalShowCV(payload.show_document_cv);
        break;
      default:
        break;
    }
  }, []);

  const uploadContext = customUploadContext ?? 'cv_upload';
  const deleteContext = customDeleteContext ?? 'cv_delete';
  const showCvContext = 'cv_show';
  const updateStatus = customUpdateStatus ?? defaultUpdateStatus;
  const status = customStatus ?? defaultStatus;

  const handleOnUpload = (event: any) => {
    updateStatus(uploadContext, 'LOADING');
    const cv = event.target.files?.[0];

    workerService
      .uploadCV({ workerId, cv })
      .then((res) => {
        updateStatus(uploadContext, 'SUCCESS', 'CV caricato con successo');
        dispatch && dispatch('worker_upload_cv', { cv: res.data.url, workerId });
      })
      .catch((err) => {
        updateStatus(uploadContext, 'ERROR', err?.data?.error);
      });
  };

  const handleDeleteCV = () => {
    updateStatus(deleteContext, 'LOADING');
    workerService
      .deleteCV(workerId)
      .then((res) => {
        updateStatus(deleteContext, 'SUCCESS', 'CV eliminato con successo');
        dispatch && dispatch('worker_delete_cv', { workerId });
      })
      .catch((err) => {
        updateStatus(deleteContext, 'ERROR', err?.data?.error);
      });
  };

  const handleWorkerUpdate = (workerId: EntityId, params: any) => {
    updateStatus(showCvContext, 'LOADING');
    workerService
      .updateWorker({ workerId, params })
      .then((res) => {
        updateStatus(showCvContext, 'SUCCESS', 'Worker aggiornato con successo');
        dispatch && dispatch('worker_update', params);
      })
      .catch((err) => {
        updateStatus(showCvContext, 'ERROR', err?.data?.error);
      });
  };

  const handleCreateJobApplication = () => {
    if (!jobPositionId || !workerId) return;
    const context: ContextType = 'create_job_application';
    updateStatus(context, 'LOADING');
    workerJobPositionService
      .createJobApplication({
        jobPositionId,
        workerId,
        statusKey: 'worker_jp_proposed',
        source: 'passive'
      })
      .then(() => {
        updateStatus(context, 'SUCCESS', 'Worker aggiunto alla panchina con successo');
        !!externalDispatch
          ? externalDispatch('add_to_bench', { worker_id: workerId })
          : reload && reload();
      })
      .finally(() => setIsCVOpened(false))
      .catch((err) => updateStatus(context, 'ERROR', err?.data?.error));
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const zoomIn = () => {
    if (cvScale < 3) setCvScale((prev) => prev + 0.5);
  };

  const zoomOut = () => {
    if (cvScale > 0.5) setCvScale((prev) => prev - 0.5);
  };

  return (
    <Box {...other}>
      <Dialog open={isCVOpened} onClose={() => setIsCVOpened(false)} maxWidth="lg" keepMounted>
        <Box
          sx={{
            px: 3,
            pt: 3,
            pb: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack direction="row" spacing={3} alignItems="center">
            <Typography variant="h6">Anteprima del CV</Typography>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Tooltip title="Ingradisci">
                <IconButton onClick={zoomIn} disabled={cvScale >= 3}>
                  <ZoomIn />
                </IconButton>
              </Tooltip>
              <Tooltip title="Rimpicciolisci">
                <IconButton onClick={zoomOut} disabled={cvScale <= 0.5}>
                  <ZoomOut />
                </IconButton>
              </Tooltip>
              <Tooltip title="Scarica il CV">
                <IconButton component={Link} href={internalCv}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              {cvScale !== 1 && <Typography variant="body2">{cvScale * 100}%</Typography>}
            </Stack>
          </Stack>
          <IconButton onClick={() => setIsCVOpened(false)}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <DialogContent>
          {!!internalCv && !!hasCVLoaded && (
            <Document
              file={internalCv}
              onLoadSuccess={onDocumentLoadSuccess}
              loading="Caricamento del CV..."
            >
              {!!numPages &&
                Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    scale={cvScale}
                    renderAnnotationLayer={false}
                  />
                ))}
            </Document>
          )}
        </DialogContent>
        {!!jobPositionId && (
          <DialogActions>
            <LoadingButton
              loading={status?.create_job_application.status === 'LOADING'}
              variant="contained"
              onClick={handleCreateJobApplication}
            >
              Aggiungi a panchina
            </LoadingButton>
          </DialogActions>
        )}
      </Dialog>
      {radioView && (
        <Grid container>
          <Grid item xs={12} md={3} sx={{ mt: 3.1 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              CV da mostrare:
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} sx={{ mt: 2, pl: 2 }}>
            <FormControl>
              <RadioGroup
                aria-labelledby={`${workerId}_cv_selection`}
                name={`${workerId}_cv_selection`}
                value={internalShowCV ? 'pdf' : 'native'}
                onChange={(e) =>
                  handleWorkerUpdate(workerId, {
                    show_document_cv: e.target.value === 'pdf' ? true : false
                  })
                }
              >
                <Grid container alignItems="center" sx={{ maxWidth: '100%' }}>
                  <Grid item xs={10}>
                    <FormControlLabel
                      value="pdf"
                      control={<Radio />}
                      label="PDF caricato dal worker"
                      disabled={!internalCv}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Nessun CV disponibile" disableHoverListener={!!internalCv}>
                      <div>
                        <IconButton disabled={!internalCv} onClick={() => setIsCVOpened(true)}>
                          <VisibilityIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel value="native" control={<Radio />} label="CV nativo" />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {!radioView && !internalCv && uploadable && (
        <>
          <input
            type="file"
            style={{ display: 'none' }}
            ref={uplaodInputRef}
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            onChange={handleOnUpload}
          />
          <LoadingButton
            loading={status?.[uploadContext].status === 'LOADING'}
            loadingPosition="start"
            color="primary"
            variant="contained"
            startIcon={status?.[uploadContext].status === 'SUCCESS' ? <Check /> : <UploadIcon />}
            onClick={() => uplaodInputRef?.current?.click()}
          >
            Carica il CV
          </LoadingButton>
        </>
      )}
      {!radioView && !internalCv && !uploadable && (
        <Tooltip title="Nessun CV caricato">
          <div>
            <IconButton disabled>
              <Iconify icon="carbon:document-pdf" />
            </IconButton>
          </div>
        </Tooltip>
      )}
      {!radioView && !!internalCv && !minimalView && (
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="inherit"
            startIcon={<Iconify icon="carbon:document-pdf" />}
            onClick={() => setIsCVOpened(true)}
          >
            Visualizza il CV
          </Button>
          <FormControlLabel
            control={
              <Switch
                id="show_document_cv"
                checked={internalShowCV}
                onChange={(e) =>
                  handleWorkerUpdate(workerId, { show_document_cv: e.target.checked })
                }
                color="primary"
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Visibile"
            labelPlacement="start"
            disabled={status?.[showCvContext].status === 'LOADING'}
          />
          <IconButton onClick={handleDeleteCV} disabled={status?.[deleteContext].status !== 'IDLE'}>
            {status?.[deleteContext].status === 'IDLE' && (
              <Delete sx={{ color: theme.palette.grey[600] }} />
            )}
            {status?.[deleteContext].status === 'LOADING' && (
              <CircularProgress thickness={1.6} size={24} sx={{ color: theme.palette.grey[600] }} />
            )}
            {status?.[deleteContext].status === 'SUCCESS' && (
              <Check sx={{ color: theme.palette.grey[600] }} />
            )}
            {status?.[deleteContext].status === 'ERROR' && (
              <Error sx={{ color: theme.palette.grey[600] }} />
            )}
          </IconButton>
        </Stack>
      )}
      {!radioView && !!internalCv && minimalView && (
        <Tooltip title="Anteprima del CV">
          <IconButton onClick={() => setIsCVOpened(true)}>
            <Iconify icon="carbon:document-pdf" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
