import { useCallback, useMemo, useState, lazy, Suspense, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MoreVert from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import debounce from 'lodash/debounce';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useAtom } from 'jotai';

import { EnhancedTableHead, EnhancedTableToolbar } from '../../../components/Table/index';
import {
  ConversationStep,
  EntityId,
  JobApplication,
  JobApplicationStatusKey,
  JobPosition,
  Occupation,
  OrderAndPagination as OrderAndPaginationType,
  Worker,
  WorkerTableType
} from '@restworld/utility-types';
import { Badge, Stack, Tooltip, Typography } from '@mui/material';
import { EmojiEvents, FlagOutlined, StickyNote2 } from '@mui/icons-material';
import TransportationOptions from 'components/TransportationOptions';
import {
  useWorkerJobPositionsService,
  WorkerListItem,
  WorkerFilterType
} from '@restworld/data-services';
import { WorkerActionType } from '../../../@types/actions';
import Label, { LabelColor } from 'components/Label';
import { formatDate, getLabelColor } from 'utils/common';
import WorkerResearchStatus from 'components/WorkerResearchStatus';
import JobApplicationSource from 'components/JobApplicationSource';
import { SIDEBAR_TRANSITION_DURATION } from 'config';
import WorkerCV from 'components/WorkerCV';
import { Checkbox } from '@mui/material';
import { formatTime } from 'utils/common';
import useDelayFunction from 'hooks/useDelayFunction';

import { UpdatedJobTypesWorker } from 'pages/showWorker';

import CropLongText from 'components/CropLongText';

import { WorkerUpdated } from 'service/worker';

import { JobApplicationUpdated } from 'service/worker';
import KlaaryoConversationComponent from 'components/KlaaryoConversationModal';
import KlaaryoConversationIcon from 'components/KlaaryoConversationIcon';
import DidCsContactCandidateFormDialog from 'components/DidCsContactCandidateFormDialog';
import { didCsContactCandidateDialogOpenAtom } from 'contexts/WorkersIndex';

const WorkerModal = lazy(() => import('components/SidebarWorkerDetails'));
const WorkerActions = lazy(() => import('components/WorkerActions'));
const Notes = lazy(() => import('components/Notes'));

const getHeadCells = (type?: WorkerTableType) => {
  let headCells = [
    {
      id: 'name',
      disablePadding: true,
      label: 'Nome'
    },
    {
      id: 'cv',
      disablePadding: false,
      label: 'CV'
    },
    {
      id: 'age',
      disablePadding: false,
      label: 'Età'
    },
    {
      id: 'transportation',
      disablePadding: false,
      label: 'Mezzo'
    },
    {
      id: 'distance',
      disablePadding: false,
      label: 'Distanza'
    },
    {
      id: 'experience',
      disablePadding: false,
      label: 'Exp'
    },
    {
      id: 'research_info',
      disablePadding: false,
      label: 'Ruolo'
    },
    {
      id: 'research_status',
      disablePadding: false,
      label: 'Stato'
    }
  ];

  if (type === 'in_bench') {
    headCells = [
      ...headCells,
      {
        id: 'source',
        disablePadding: false,
        label: 'Fonte'
      },
      {
        id: 'status',
        disablePadding: false,
        label: 'Candidatura'
      },
      {
        id: 'klaaryo_conversation',
        disablePadding: false,
        label: 'Conversazione'
      }
    ];
  }

  return headCells;
};

type Props = {
  data: Array<WorkerListItem>;
  orderAndPagination: OrderAndPaginationType;
  setOrderAndPagination: (orderAndPagination: OrderAndPaginationType) => void;
  reload: () => void;
  dispatch?: (action: WorkerActionType, payload: any) => void;
  jobPosition?: JobPosition;
  type?: WorkerTableType;
  onTabChange: (type?: WorkerTableType) => void;
  setActiveTab: (tab: number) => void;
  onRefresh?: () => void;
  filters?: WorkerFilterType;
  onFilterApplied?: (filters: WorkerFilterType) => void;
};

export default function EnhancedTable({
  data,
  orderAndPagination,
  setOrderAndPagination,
  reload,
  dispatch,
  jobPosition,
  type,
  onTabChange,
  onRefresh,
  setActiveTab,
  filters,
  onFilterApplied
}: Props) {
  // const [selected, setSelected] = React.useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedMenu, setOpenedMenu] = useState<{
    open: boolean;
    worker?: Worker;
    jobApplication?: JobApplication;
    conversationStep?: ConversationStep;
  }>({
    open: false
  });
  const [openedStatusMenu, setOpenedStatusMenu] = useState<{
    open: boolean;
    workerId?: EntityId;
    jobApplication?: JobApplication;
  }>({ open: false });
  const debouncedSetOrderAndPagination = debounce(setOrderAndPagination, 500);
  const workerJobPositionService = useWorkerJobPositionsService();
  const [workerModal, setWorkerModal] = useState<{
    isOpen: boolean;
    worker?: UpdatedJobTypesWorker;
    jobApplication?: JobApplication;
  }>({
    isOpen: false,
    worker: undefined
  });
  const [notesModal, setNotesModal] = useState<{ isOpen: boolean; worker_id?: EntityId }>({
    isOpen: false
  });
  const [klaaryoConversationModalOptions, setKlaaryoConversationModalOptions] = useState<{
    isOpen: boolean;
    jobApplicationId?: string;
  }>({
    isOpen: false
  });

  const [selectedWorkers, setSelectedWorkers] = useState<Array<string>>([]);

  const [disabledMenuButtons, setDisabledMenuButtons] = useState<boolean>(false);

  const [selectedWorkerId, setSelectedWorkerId] = useState<string>();

  const [didCsContactCandidateDialogOpen, setDidCsContactCandidateDialogOpen] = useAtom(
    didCsContactCandidateDialogOpenAtom
  );

  const { delayFunction } = useDelayFunction();

  useEffect(() => {
    setWorkerModal((prev) => ({ ...prev, isOpen: false, jobApplication: undefined }));
    setSelectedWorkers([]);
  }, [onTabChange]);

  const isSelected = useCallback(
    (worker_id: string) => {
      if (selectedWorkers.length > 0) {
        return !!selectedWorkers.find((user_id) => user_id === worker_id);
      } else {
        return false;
      }
    },
    [selectedWorkers]
  );

  const WorkerList = useMemo(() => {
    return (
      <TableBody>
        {data.map((row: WorkerListItem, index: number) => {
          const worker = row.worker as WorkerUpdated;
          const jobApplication = row?.job_application as JobApplicationUpdated;

          const qualificationStep = jobApplication?.conversation_steps?.find(
            (step) => step?.category_var?.key === 'qualification' && step?.status === 'closed'
          );

          const isJrForQualificationStep = qualificationStep?.owner?.roles
            ?.map((role) => role?.key)
            .includes('junior_recruiter');

          const proposalStep = jobApplication?.conversation_steps?.find((step) => {
            return step?.category_var?.key === 'jp_proposal' && step?.status === 'closed';
          });

          const isJrForProposalStep = proposalStep?.owner?.roles
            ?.map((role) => role?.key)
            .includes('junior_recruiter');

          const isItemSelected: boolean = isSelected(worker.user_id);
          const labelId = `enhanced-table-checkbox-${index}`;
          const age = !!worker.birthdate
            ? new Date().getFullYear() - new Date(worker.birthdate).getFullYear()
            : '-';
          const picture = worker.assets?.find((a: any) => a.type === 'picture');

          let workerOccupations = [];
          !!worker?.research_info &&
            (!!worker?.research_info?.occupations && worker?.research_info?.occupations?.length > 0
              ? worker?.research_info?.occupations?.forEach((occupation: Occupation) => {
                  workerOccupations.push(occupation?.label_it?.label);
                })
              : workerOccupations.push(worker?.research_info?.domain_interest_var?.label));

          const distance = ((row?.distance || 0) / 1000).toFixed(0);

          const notesLength =
            (worker?.notes?.length || 0) + (worker?.worker_shortlist_notes?.length || 0);

          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={worker.user_id as string}
              selected={isItemSelected}
              sx={
                row.job_application?.status_key === 'worker_jp_discarded'
                  ? { td: { color: 'grey.500' } }
                  : {}
              }
            >
              <TableCell>
                <Checkbox
                  disabled={row?.job_application?.status_key === 'worker_jp_discarded'}
                  checked={isItemSelected}
                  onChange={() => {
                    selectedWorkers.includes(worker.user_id)
                      ? setSelectedWorkers(selectedWorkers.filter((id) => id !== worker.user_id))
                      : setSelectedWorkers([...selectedWorkers, worker.user_id]);
                  }}
                />
              </TableCell>
              <TableCell component="th" id={labelId} scope="row" padding="none">
                <CardHeader
                  avatar={
                    <Badge
                      badgeContent={
                        <Stack flexDirection="row" alignItems="center">
                          {row?.active && (
                            <Tooltip title="Il candidato è attivo in un'altra rosa">
                              <EmojiEvents sx={{ fontSize: 15 }} />
                            </Tooltip>
                          )}
                          {worker?.worker_reports?.length !== 0 && (
                            <Tooltip title="Candidato è stato segnalato">
                              <FlagOutlined sx={{ fontSize: 15 }} />
                            </Tooltip>
                          )}
                        </Stack>
                      }
                      invisible={!row.active && worker?.worker_reports?.length === 0}
                      color={worker?.worker_reports?.length !== 0 ? 'error' : 'warning'}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      overlap="circular"
                    >
                      <Avatar
                        alt={worker.name}
                        src={picture?.url}
                        onClick={() => {
                          setWorkerModal({ isOpen: true, worker, jobApplication });
                        }}
                        sx={{ cursor: 'pointer' }}
                      />
                    </Badge>
                  }
                  title={
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontSize={15}>
                        {worker.name} {worker.surname}
                      </Typography>

                      {proposalStep && proposalStep?.closed_at && !qualificationStep && (
                        <Tooltip
                          title={`Proposta ricevuta il ${formatDate(
                            proposalStep.closed_at,
                            'dd/MM/yyyy HH:mm'
                          )}`}
                        >
                          <DoneIcon
                            fontSize="small"
                            color={
                              !!isJrForProposalStep && isJrForProposalStep ? 'secondary' : 'primary'
                            }
                          />
                        </Tooltip>
                      )}
                      {qualificationStep && qualificationStep?.closed_at && (
                        <Tooltip
                          title={`Qualificazione completata il ${formatDate(
                            qualificationStep?.closed_at,
                            'dd/MM/yyyy HH:mm'
                          )}`}
                        >
                          <DoneAllIcon
                            fontSize="small"
                            color={
                              !!isJrForQualificationStep && isJrForQualificationStep
                                ? 'secondary'
                                : 'primary'
                            }
                          />
                        </Tooltip>
                      )}
                      {/* {row?.job_application?.is_contacted && (
                        <Tooltip
                          title={
                            <Typography sx={{ whiteSpace: 'pre-line' }} variant="caption">
                              {`Contattato/a il ${
                                jobApplication?.is_contacted_at
                                  ? formatDate(jobApplication.is_contacted_at, 'dd/MM/yyyy HH:mm')
                                  : ''
                              }\nDa ${jobApplication.is_contacted_by_rw_member?.name || 'N.D.'} ${
                                jobApplication.is_contacted_by_rw_member?.surname || ''
                              }`}
                            </Typography>
                          }
                        >
                          <DoneIcon
                            fontSize="small"
                            color={
                              isContactedByRoles?.includes('junior_recruiter')
                                ? 'secondary'
                                : 'primary'
                            }
                          />
                        </Tooltip>
                      )} */}
                    </Stack>
                  }
                  disableTypography
                  sx={
                    row.job_application?.status_key === 'worker_jp_discarded'
                      ? { textDecoration: 'line-through', color: 'grey.500', p: 0 }
                      : { p: 0 }
                  }
                />
              </TableCell>
              {/* {type === 'in_bench' && (
                <TableCell>
                  <Stack alignItems="center">
                    {row?.job_application?.is_contacted && (
                      <Tooltip title="Il candidato è già stato contattato">
                        <DoneIcon fontSize="small" color="primary" />
                      </Tooltip>
                    )}
                  </Stack>
                </TableCell>
              )} */}
              <TableCell align="left" sx={{ pl: '10px !important' }}>
                <WorkerCV
                  workerId={worker.user_id}
                  cv={worker.cv}
                  showCV
                  minimalView
                  uploadable={false}
                  jobPositionId={jobPosition?.id}
                  dispatch={dispatch}
                  reload={reload}
                />
              </TableCell>
              <TableCell align="left">{age}</TableCell>
              <TableCell align="left">
                <TransportationOptions options={worker.transportation_options} />
              </TableCell>
              <TableCell align="left">{distance} km</TableCell>
              <TableCell align="left" sx={{ minWidth: '100px' }}>
                {worker.experience_var?.label}
              </TableCell>
              <TableCell align="left">
                <CropLongText labels={workerOccupations} variant="body2" />
              </TableCell>
              <TableCell align="right">
                <WorkerResearchStatus
                  workerId={worker.user_id}
                  researchStatusLastUpdated={worker.research_status_last_updated}
                  researchStatus={worker.research_status}
                  dispatch={dispatch}
                  fullWidth
                  sx={{ justifyContent: 'right' }}
                />
              </TableCell>
              {type === 'in_bench' && (
                <>
                  <TableCell align="left">
                    <JobApplicationSource
                      source={row.job_application?.source}
                      ambassadorCode={(row.job_application as any)?.ambassador_code}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={(e: any) =>
                      handleStatusMenuClick(e, row.worker?.user_id, row.job_application)
                    }
                    sx={{ cursor: 'pointer' }}
                  >
                    <Tooltip title="Clicca per cambiare lo stato della candidatura">
                      <Label
                        color={
                          getLabelColor(
                            row.job_application?.status?.sentiment,
                            'default',
                            row.job_application?.status_key,
                            row?.job_application?.last_bench_status_change_timestamp
                          ) as LabelColor
                        }
                        sx={{
                          cursor: 'pointer',
                          height: 30,
                          p: 2,
                          pt: 3
                        }}
                      >
                        <Stack direction="column" spacing={0.5}>
                          <div>{row.job_application?.status?.label}</div>
                          <Typography
                            variant="caption"
                            fontWeight="regular"
                            sx={{ textAlign: 'center' }}
                          >
                            il{' '}
                            {formatTime({
                              dateTime:
                                row.job_application?.last_bench_status_change_timestamp || '',
                              noTime: true
                            })}
                          </Typography>
                        </Stack>
                      </Label>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <KlaaryoConversationIcon
                      showLabel={true}
                      jobApplication={jobApplication}
                      onClick={() =>
                        setKlaaryoConversationModalOptions({
                          isOpen: true,
                          jobApplicationId: jobApplication?.id
                        })
                      }
                    />
                  </TableCell>
                </>
              )}
              <TableCell sx={{ minWidth: 168 }}>
                <Stack direction="row" spacing={0.5}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      setWorkerModal({ isOpen: true, worker, jobApplication });
                    }}
                  >
                    Profilo
                  </Button>
                  <Tooltip title="Note">
                    <IconButton
                      onClick={() => setNotesModal({ isOpen: true, worker_id: worker.user_id })}
                    >
                      <Badge badgeContent={notesLength} color="info">
                        <StickyNote2 />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    onClick={(e: any) =>
                      handleMenuClick(
                        e,
                        worker,
                        row.job_application,
                        proposalStep || qualificationStep
                      )
                    }
                    component="span"
                    aria-controls={`more-menu_${worker.user_id}`}
                    aria-haspopup="true"
                    aria-expanded={
                      openedMenu.open && openedMenu.worker?.user_id === worker.user_id
                        ? 'true'
                        : undefined
                    }
                  >
                    <MoreVert />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
        {type === 'in_bench' && !data.length && (
          <TableRow hover>
            <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
              <ClearAllIcon sx={{ fontSize: 48 }} />
              <br />
              Non hai inserito nessun workers in panchina.
              <br />
              Torna nella tab{' '}
              <Button
                onClick={() => {
                  setActiveTab(0);
                  onTabChange();
                }}
              >
                workers
              </Button>{' '}
              per popolare questa tabella
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }, [
    data,
    onTabChange,
    type,
    setActiveTab,
    openedMenu,
    dispatch,
    jobPosition?.id,
    reload,
    selectedWorkers,
    isSelected
  ]);

  const handleMenuClick = (
    event: any,
    worker: Worker,
    jobApplication?: JobApplication,
    conversationStep?: ConversationStep
  ) => {
    setOpenedMenu({ open: true, worker, jobApplication, conversationStep });
    setSelectedWorkerId(worker?.user_id);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = useCallback(() => {
    setOpenedMenu({ open: false });
    setAnchorEl(null);
  }, []);

  const handleStatusMenuClick = (
    event: any,
    user_id: EntityId,
    jobApplication?: JobApplication
  ) => {
    setOpenedStatusMenu({ open: true, workerId: user_id, jobApplication });
    setAnchorEl(event.currentTarget);
  };

  const handleStatusMenuClose = () => {
    setDisabledMenuButtons(false);
    setOpenedStatusMenu({ open: false });
    setAnchorEl(null);
  };

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderAndPagination.orderBy === property && orderAndPagination.order === 'asc';
    setOrderAndPagination({
      ...orderAndPagination,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    });
  };

  const onStatusChange = (statusKey: string, workerId?: string) => {
    if (jobPosition && jobPosition?.id && !!workerId) {
      setDisabledMenuButtons(true);
      delayFunction({
        actionName: 'aggiornamento dello stato della candidatura',
        onCancel: () => {
          setDisabledMenuButtons(false);
        },
        functionToExecute: () => {
          workerJobPositionService
            .updateJobApplication({
              jobPositionId: jobPosition?.id,
              workerId,
              params: {
                status_key: statusKey as JobApplicationStatusKey
              }
            })
            .then(() => {
              reload();
              if (statusKey === 'worker_jp_not_selected') setDidCsContactCandidateDialogOpen(true);
            })
            .finally(() => handleStatusMenuClose());
        }
      });
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setSelectedWorkers([]);
    setOrderAndPagination({
      ...orderAndPagination,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setSelectedWorkers([]);
    setOrderAndPagination({
      ...orderAndPagination,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  const handleSelectAllClick = useCallback(() => {
    const totalValidWorkersLength = data?.filter(
      (wli) => wli?.job_application?.status_key !== 'worker_jp_discarded'
    ).length;

    if (selectedWorkers.length === totalValidWorkersLength) {
      setSelectedWorkers([]);
    } else {
      setSelectedWorkers(() =>
        data
          .filter(
            (wli: WorkerListItem) => wli?.job_application?.status_key !== 'worker_jp_discarded'
          )
          .map((wli: WorkerListItem) => wli.worker.user_id)
      );
    }
  }, [data, selectedWorkers]);

  const resetSelectedWorkers = useCallback(() => {
    setSelectedWorkers([]);
  }, []);

  const handleStatusMenuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onStatusChange(event.target.value, openedStatusMenu.workerId);
    setSelectedWorkerId(openedStatusMenu.workerId);
  };

  const closeKlaaryoConversationModal = useCallback(() => {
    setKlaaryoConversationModalOptions({ isOpen: false });
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <KlaaryoConversationComponent
        isOpen={klaaryoConversationModalOptions.isOpen}
        onClose={closeKlaaryoConversationModal}
        jobApplicationId={klaaryoConversationModalOptions?.jobApplicationId}
        refresh={reload}
        jobPositionSlug={jobPosition?.semantic_unique_name}
      />
      <Suspense fallback={<></>}>
        <WorkerModal
          isOpen={workerModal.isOpen}
          handleToggle={() => {
            setWorkerModal((prev) => ({ ...prev, isOpen: false, jobApplication: undefined }));
            setTimeout(
              () =>
                setWorkerModal((prev) => ({
                  ...prev,
                  worker: undefined,
                  jobApplication: undefined
                })),
              SIDEBAR_TRANSITION_DURATION
            );
          }}
          worker={workerModal.worker}
          jobPosition={jobPosition}
          reload={reload}
          type={type}
          dispatch={dispatch}
          parentPage="index_workers"
          resetSelectedWorkers={resetSelectedWorkers}
          jobApplication={workerModal.jobApplication}
        />
      </Suspense>
      <Suspense fallback={<></>}>
        <Notes
          isModal
          setIsModalOpen={(value) => setNotesModal({ isOpen: value })}
          isModalOpen={notesModal.isOpen}
          category="worker"
          entityId={notesModal.worker_id || ''}
          jobPositionId={type === 'in_bench' ? jobPosition?.id : undefined}
        />
      </Suspense>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selectedWorkers.length}
          searchValue={orderAndPagination.searchValue}
          onSearchChange={(e: any) =>
            debouncedSetOrderAndPagination({
              ...orderAndPagination,
              page: 0,
              searchValue: e.target.value
            })
          }
          onRefresh={onRefresh}
          title=""
          type={type}
          dispatch={dispatch}
          reload={reload}
          jobPosition={jobPosition}
          selectedWorkers={selectedWorkers}
          setSelectedWorkers={setSelectedWorkers}
          filters={filters}
          onFilterApplied={onFilterApplied}
          onDisableSemanticSearch={() => {
            debouncedSetOrderAndPagination({
              ...orderAndPagination,
              searchValue: ''
            });
          }}
          showSwitch
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <EnhancedTableHead
              order={orderAndPagination.order}
              orderBy={orderAndPagination.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              sortableColumns={['name', 'age', 'distance']}
              rowCount={
                data?.filter((wli) => wli?.job_application?.status_key !== 'worker_jp_discarded')
                  .length
              }
              headCells={getHeadCells(type)}
              numSelected={selectedWorkers.length}
            />
            {WorkerList}
          </Table>
        </TableContainer>
        <Menu
          onClose={handleStatusMenuClose}
          anchorEl={anchorEl}
          open={openedStatusMenu.open}
          id={`worker_status_menu`}
          MenuListProps={{
            'aria-labelledby': `worker_status_menu_basic-button`
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={handleStatusMenuChange}
            >
              <MenuItem>
                <FormControlLabel
                  disabled={disabledMenuButtons}
                  value="worker_jp_proposed"
                  control={<Radio />}
                  checked={openedStatusMenu.jobApplication?.status_key === 'worker_jp_proposed'}
                  label={'Posizione proposta'}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  value="worker_jp_interested"
                  disabled={disabledMenuButtons}
                  control={<Radio />}
                  checked={openedStatusMenu.jobApplication?.status_key === 'worker_jp_interested'}
                  label={'Interessato'}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  disabled={disabledMenuButtons}
                  value="worker_jp_not_interested"
                  control={<Radio />}
                  checked={
                    openedStatusMenu.jobApplication?.status_key === 'worker_jp_not_interested'
                  }
                  label={'Non Interessato'}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  disabled={disabledMenuButtons}
                  value="worker_jp_not_selected"
                  control={<Radio />}
                  checked={openedStatusMenu.jobApplication?.status_key === 'worker_jp_not_selected'}
                  label={'Idoneo non selezionato'}
                />
              </MenuItem>
            </RadioGroup>
          </FormControl>
        </Menu>
        <Suspense fallback={<></>}>
          <WorkerActions
            anchorEl={anchorEl}
            isOpen={openedMenu.open}
            id={`worker_actions_menu`}
            worker={openedMenu.worker}
            jobPosition={jobPosition}
            handleMenuClose={handleMenuClose}
            reload={reload}
            dispatch={dispatch}
            type={type}
            jobApplication={openedMenu.jobApplication}
            parentPage="index_workers"
            resetSelectedWorkers={resetSelectedWorkers}
            conversationStep={openedMenu.conversationStep}
          />
        </Suspense>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={orderAndPagination.count || 0}
          rowsPerPage={orderAndPagination.rowsPerPage}
          labelRowsPerPage="Candidati per pagina"
          page={orderAndPagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={filters?.direct_semantic_search ? { pointerEvents: 'none', opacity: 0.4 } : {}}
        />
      </Paper>

      <DidCsContactCandidateFormDialog
        isDialogVisible={didCsContactCandidateDialogOpen}
        onCloseDialog={() => setDidCsContactCandidateDialogOpen(false)}
        jobPositionId={jobPosition?.id}
        workerId={selectedWorkerId || ''}
      />
    </Box>
  );
}
