import { styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, bgcolor, color }: any) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bgcolor || theme.palette.common.white,
    color: color || 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[10],
    fontSize: 11
  }
}));

export default LightTooltip;
