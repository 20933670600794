import { atom } from 'jotai';
export const semanticSearchAtom = atom<string>('');
export const semanticSearchSwitchAtom = atom<boolean>(false);
export const maxWorkerRestaurantDistanceAtom = atom<number | undefined>(undefined);

export type WorkersIndexAlgorithm =
  | 'deterministic_filter'
  | 'semantic_search'
  | 'text_search'
  | 'direct_semantic_search'
  | 'collaborative_filter'
  | undefined;

export const workerSearchAlgorithmAtom = atom<WorkersIndexAlgorithm>(undefined);

export const didCsContactCandidateDialogOpenAtom = atom<boolean>(false);
