import { Grid, Chip, Typography, useTheme } from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';

import { Variable } from '@restworld/utility-types';
import LightTooltip from './LightTooltip';

interface Props {
  options: Variable[];
  selectedOption?: Variable;
  onClick: (option: Variable) => void;
  showOther?: boolean;
}
export default function ChipOptions({ options, selectedOption, onClick, showOther }: Props) {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={1}>
        {options.map((option) => (
          <Grid item key={option.id}>
            <Chip
              icon={
                <LightTooltip
                  title={<Typography>{option.description || ''}</Typography>}
                  disableHoverListener={!option.description}
                  // sx={{ bgcolor: 'grey.100' }}
                  color={theme.palette.grey[800]}
                >
                  <InfoIcon
                    sx={{
                      ml: theme.spacing(0.5),
                      color:
                        selectedOption?.id === option.id
                          ? theme.palette.primary.contrastText
                          : theme.palette.grey[500]
                    }}
                    fontSize="small"
                  />
                </LightTooltip>
              }
              // icon={<InfoIcon />}
              label={option.label}
              color={selectedOption?.id === option.id ? 'primary' : 'default'}
              onClick={() => onClick(option)}
            />
          </Grid>
        ))}
        {showOther && (
          <Grid item xs={12}>
            <Chip
              label="Altro"
              color={selectedOption?.id === 'other' ? 'primary' : 'default'}
              onClick={() =>
                onClick({
                  id: 'other',
                  key: 'other',
                  label: 'Altro'
                })
              }
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
